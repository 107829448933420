import {
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,
} from '../constants/forgotpassword';

export const forgotPasswordRequest = email => ({
  type: FORGOT_PASSWORD_REQUEST,
  email,
});

export const forgotPasswordSuccess = responseData => ({
  type: FORGOT_PASSWORD_SUCCESS,
  responseData,
});

export const forgotPasswordError = err => ({
  type: FORGOT_PASSWORD_ERROR,
  err,
});

export const resetForgotPassword = () => ({
  type: 'RESET_FORGOT_PASSWORD_SUCCESS',
});
