import * as CONSTANTS from '../constants/login'

export const autoLoginRequest = () => ({
  type: CONSTANTS.AUTO_LOGIN_REQUEST
})

export const autoLoginSuccess = data => ({
  type: CONSTANTS.AUTO_LOGIN_SUCCESS,
  data
})

export const autoLoginError = error => ({
  type: CONSTANTS.AUTO_LOGIN_ERROR,
  error
})

export const loginRequest = data => ({
  type: CONSTANTS.LOGIN_REQUEST,
  data
})

export const loginSuccess = data => ({
  type: CONSTANTS.LOGIN_SUCCESS,
  data
})

export const loginError = error => ({
  type: CONSTANTS.LOGIN_ERROR,
  error
})

export const logout = () => ({
  type: CONSTANTS.LOGOUT,
})

export const enableWebWorker = (data) => ({
  type: CONSTANTS.ENABLE_WEB_WORKER,
  data: data,
})

export const disableWebWorker = () => ({
  type: CONSTANTS.DISABLE_WEB_WORKER,
})

export const showLoginModal = pathname => ({
  type: CONSTANTS.SHOW_LOGIN_MODAL,
  pathname,
})

export const hideLoginModal = () => ({
  type: CONSTANTS.HIDE_LOGIN_MODAL,
})

export const setUserAuthenticatedStatus = status => ({
  type: CONSTANTS.SET_USER_AUTHENTICATED_STATUS,
  status,
})

export const hideLoginModalAndWipeLocalStorage = () => ({
  type: CONSTANTS.HIDE_LOGIN_MODAL_AND_WIPE_LOCAL_STORAGE,
})

export const loginFromModalRequest = (userName, password) => ({
  type: CONSTANTS.LOGIN_FROM_MODAL_REQUEST,
  userName,
  password,
});

export const loginFromModalSuccess = accessData => ({
  type: CONSTANTS.LOGIN_FROM_MODAL_SUCCESS,
  payload: accessData.data,
});

export const loginFromModalError = () => ({
  type: CONSTANTS.LOGIN_FROM_MODAL_ERROR,
});
