export const ADMIN_GET_TRADED_SYMBOLS_REQUEST = 'ADMIN_GET_TRADED_SYMBOLS_REQUEST'
export const ADMIN_GET_TRADED_SYMBOLS_SUCCESS = 'ADMIN_GET_TRADED_SYMBOLS_SUCCESS'
export const ADMIN_GET_TRADED_SYMBOLS_ERROR = 'ADMIN_GET_TRADED_SYMBOLS_ERROR'

export const ADMIN_CREATE_TRADED_SYMBOL_REQUEST = 'ADMIN_CREATE_TRADED_SYMBOL_REQUEST'
export const ADMIN_CREATE_TRADED_SYMBOL_SUCCESS = 'ADMIN_CREATE_TRADED_SYMBOL_SUCCESS'
export const ADMIN_CREATE_TRADED_SYMBOL_ERROR = 'ADMIN_CREATE_TRADED_SYMBOL_ERROR'

export const ADMIN_SEARCH_USERS_REQUEST = 'ADMIN_SEARCH_USERS_REQUEST'
export const ADMIN_SEARCH_USERS_SUCCESS = 'ADMIN_SEARCH_USERS_SUCCESS'
export const ADMIN_SEARCH_USERS_ERROR = 'ADMIN_SEARCH_USERS_ERROR'
export const ADMIN_SEARCH_USERS_CLEAR = 'ADMIN_SEARCH_USERS_CLEAR'

export const ADMIN_SEARCH_REPORTS_REQUEST = 'ADMIN_SEARCH_REPORTS_REQUEST'
export const ADMIN_SEARCH_REPORTS_SUCCESS = 'ADMIN_SEARCH_REPORTS_SUCCESS'
export const ADMIN_SEARCH_REPORTS_ERROR = 'ADMIN_SEARCH_REPORTS_ERROR'
export const ADMIN_SEARCH_REPORTS_CLEAR = 'ADMIN_SEARCH_REPORTS_CLEAR'
