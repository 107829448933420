import { put, takeLatest, call, delay } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import * as CONSTANTS from '../../constants/researches';
import * as RESEARCH_ACTIONS from '../../actions/researches';
import { history } from "../../../configureStore";
import request from "../../../utils/apiRequest";
import * as ANALYTICS_ACTIONS from '../../actions/analytics'
import * as ADMIN_ACTIONS from '../../actions/admin'

function* getResearchFeeds(action) {
  try {
    const requestData = {
      offset: action.offset
    };
    const feeds = yield call(
      request,
      "research/feed?",
      "GET",
      requestData,
      null,
      true
    );
    yield put(RESEARCH_ACTIONS.getResearchFeedSuccess(feeds));
  } catch (err) {}
}

function* reportSearchRequest(action) {
  try {

    const { q , offset } = action;
    const requestData = { q : q, offset : offset };
    const feeds = yield call(
      request,
      `admin/reportLookup?`,
      "GET",
      requestData,
      true
    );
    yield put(RESEARCH_ACTIONS.reportSearchSuccess(feeds));
  } catch (err) {
    yield put(RESEARCH_ACTIONS.reportSearchError(err));
  }
}

function* filterSearch(action) {
  try {
    let requestData = {};
    const { searchKeys, offset } = action;
    if (searchKeys.keyword) {
      requestData = {
        keyword: searchKeys.keyword,
        offset
      };
    } else if (searchKeys.token) {
      requestData = {
        token: searchKeys.token,
        offset
      };
    } else {
      requestData = {
        category: searchKeys.category,
        offset
      };
    }
    const researches = yield call(
      request,
      "research/search",
      "GET",
      requestData,
      true
    );
    yield put(RESEARCH_ACTIONS.filterResearchSuccess(researches));
  } catch (err) {}
}

function* getUpvotes(action) {
  try {
    const responseData = yield call(
      request,
      "research/upvotes/user",
      "GET",
      null,
      true
    );
    yield put(RESEARCH_ACTIONS.getUpvotesSuccess(responseData));
  } catch (err) {}
}

function* getDownvotes(action) {
  try {
    const responseData = yield call(
      request,
      "research/downvotes/user",
      "GET",
      null,
      true
    );
    yield put(RESEARCH_ACTIONS.getDownvotesSuccess(responseData));
  } catch (err) {}
}

function* postUpvote(action) {
  try {
    const { userId, resourceId, resourceCreatorId } = action;
    const requestData = {
      type: "upvote",
      userId,
      resourceId,
      resourceType: "report",
      resourceCreatorId
    };
    yield call(request, "/analytics/event", "POST", requestData, true);
    yield put(RESEARCH_ACTIONS.getUpvotesRequest());
  } catch (err) {}
}

function* postDownvote(action) {
  try {
    const requestData = { resourceId: action.resourceId };
    yield call(request, "research/downvote", "POST", requestData, true);
    yield put(RESEARCH_ACTIONS.updateDownvoteCount(action.resourceId, 1));
    yield put(RESEARCH_ACTIONS.getDownvotesRequest());
  } catch (err) {}
}

function* removeUpvote(action) {
  try {
    const requestData = { resourceId: action.resourceId };
    yield call(request, "research/removeUpvote", "POST", requestData, true);
    yield put(RESEARCH_ACTIONS.updateUpvoteCount(action.resourceId, -1));
    yield put(RESEARCH_ACTIONS.getUpvotesRequest());
  } catch (err) {}
}

function* removeDownvote(action) {
  try {
    const requestData = { resourceId: action.resourceId };
    yield call(request, "research/removeDownvote", "POST", requestData, true);
    yield put(RESEARCH_ACTIONS.updateDownvoteCount(action.resourceId, -1));
    yield put(RESEARCH_ACTIONS.getDownvotesRequest());
  } catch (err) {}
}

function* getReportVoteUsers(action) {
  try {
    const type = action.voteType;
    const id = action.id;
    const requestData = { id };
    const responseData = yield call(
      request,
      `research/${type}/report`,
      "GET",
      requestData,
      true
    );
    yield put(RESEARCH_ACTIONS.getReportVoteUsersSuccess(responseData));
  } catch (err) {}
}

function* getReport(action) {
  try {
    const responseData = yield call(
      request,
      `/research/contributor/search?_id=${action.id}`,
      'GET',
    )

    yield put(RESEARCH_ACTIONS.getDraftSuccess(responseData))
  } catch (err) {
    toast.error(err.reason)
  }
}

function* getUsersDrafts(action) {
  try {
    const { id } = action;
    const requestData = id;
    const responseData = yield call(
      request,
      `research/contributor/drafts?userId=${id}`,
      "GET",
      null,
      true
    );

    yield put(RESEARCH_ACTIONS.getUsersDraftsSuccess(responseData));
  } catch (err) {}
}

function* createDraft() {
  try {
    const responseData = yield call(
      request,
      '/research/contributor/newDraft',
      'POST',
    )

    yield put(RESEARCH_ACTIONS.createDraftSuccess(responseData))
  } catch (err) {
    toast.error(err.reason)
    yield put(RESEARCH_ACTIONS.createDraftError(err))
  }
}

function* saveDraft(action) {
  try {
    const body = action.data.docs[0]
    const responseData = yield call(
      request,
      '/research/contributor/editDraft',
      'POST',
      body,
    )

    yield put(RESEARCH_ACTIONS.saveDraftSuccess())
  } catch (err) {
    toast.error(err.reason)
    yield put(RESEARCH_ACTIONS.saveDraftError(err))
  }
}

function* deleteDraft(action) {
  try {
    const body = {
      _id: action.id,
      userId: action.userId
    }
    const responseData = yield call(
      request,
      '/research/contributor/deleteReport',
      'POST',
      body,
    )

    yield put(ADMIN_ACTIONS.searchReportsRequest(action.params))
  } catch (err) {
    toast.error(err.reason)
  }
}

function* publishArticle(action) {
  try {
    const responseData = yield call(
      request,
      '/research/contributor/publish',
      'POST',
      action.data
    )

    let newTitle = action.data.title.toLowerCase().replace(/%/g, '').replace(/\s+/g, '-').replace(/\?/g,'') + '-' + action.data._id
    history.push(`/research/${newTitle}`)
  } catch (err) {
    toast.error(err.reason)
  }
}

function* getBookmarks(action) {
  try {
    const responseData = yield call(
      request,
      "research/bookmarks",
      "GET",
      null,
      true
    );
    yield put(RESEARCH_ACTIONS.getBookmarksSuccess(responseData));
  } catch (err) {}
}

function* getTrendingArticles(action) {
  try {
    const responseData = yield call(
      request,
      "research/trendingReports",
      "GET",
      null,
      true
    );
    yield put(RESEARCH_ACTIONS.getTrendingArticlesSuccess(responseData));
  } catch (err) {}
}

function* getTrendingTopics(action) {
  try {

    const responseData = yield call(
      request,
      `research/trendingTopics?timeFrame=${action.timeFrame}`,
      'GET',
      null,
      true,
    )

    yield put(RESEARCH_ACTIONS.getTrendingTopicsSuccess(responseData))
  } catch (err) {}
}

function* getResearchUserFeed(action) {
  try {
    const requestData = {
      offset: action.offset
    };
    const responseData = yield call(
      request,
      "research/user/feed",
      "GET",
      requestData,
      true
    );
    yield put(RESEARCH_ACTIONS.getResearchUserFeedSuccess(responseData));
  } catch (err) {}
}

function* readArticle(action) {
  try {
    const { articleId } = action
    const responseData = yield call(
      request,
      `/research/report?_id=${articleId}`,
      'GET',
    )

    yield put(RESEARCH_ACTIONS.readArticleSuccess(responseData))
    // const {contributor, report} = responseData[0]
    // yield put(RESEARCH_ACTIONS.readArticleSuccess(responseData));
    // const viewData = {
    //   type: 'view',
    //   resourceId: articleId,
    //   resourceCreatorId: contributor.userId,
    //   source: parameters.source || null
    // }
    // yield put(ANALYTICS_ACTIONS.postEventRequest(viewData))
  } catch (err) {
    if (err.reason === 'Report not found') {
      yield put(RESEARCH_ACTIONS.reportNotFound())
    }
  }
}

function* getRelatedArticles(action) {
  try {
    const responseData = yield call(
      request,
      `/research/relatedReports?_id=${action.id}`,
      'GET',
    )

    yield put(RESEARCH_ACTIONS.getRelatedArticlesSuccess(responseData))
  } catch (err) {

  }
}

function* postComment(action) {
  try {
    const { userId, resourceId, resourceCreatorId, comment } = action;
    const requestData = {
      userId,
      resourceId,
      resourceCreatorId,
      comment
    };
    yield call(request, "research/addComment", "POST", requestData, true);
    const responseData = yield call(
      request,
      `research/comments?resourceId=${resourceId}`,
      "GET",
      null,
      true
    );
    yield put(RESEARCH_ACTIONS.getCommentsSuccess(responseData));
  } catch (err) {}
}

export function* getComments(action) {
  try {
    const { resourceId } = action;
    let offset = action.offset ? action.offset : 0;
    const responseData = yield call(
      request,
      `research/comments?resourceId=${resourceId}&offset=${offset}`,
      "GET",
      null,
      true
    );
    yield put(RESEARCH_ACTIONS.getCommentsSuccess(responseData));
  } catch (err) {}
}

function* articleImageUpload(action) {
  try {
    let formData = new FormData()
    formData.append('file', action.image)
    const responseData = yield call(
      request,
      '/file/uploadFile',
      'POST',
      formData,
      false,
      true
    )

    const newDraftData = action.formData
    newDraftData.docs[0].image = responseData.url
    yield put(RESEARCH_ACTIONS.saveDraftRequest(newDraftData))
    yield put(RESEARCH_ACTIONS.articleImageUploadSuccess())
  } catch (err) {
    toast.error(err.reason)
    yield put(RESEARCH_ACTIONS.articleImageUploadError(err))
  }
}

function* uploadDocument(action) {
  try {
    let formData = new FormData()
    formData.append('file', action.doc)
    const responseData = yield call(
      request,
      '/file/docConverter',
      'POST',
      formData,
      true,
      true
    )

    const newDraftData = action.formData
    newDraftData.docs[0].body = responseData.data
    yield put(RESEARCH_ACTIONS.saveDraftRequest(newDraftData.docs[0]))
    yield put(RESEARCH_ACTIONS.uploadDocumentSuccess())
  } catch (err) {
    if (err == "TypeError: Failed to fetch") {
      toast.error("File is too large (2mb max)");
    } else {
      toast.error(err.reason);
    }
  }
}

function* getTopicsList(action) {
  try {
    const responseData = yield call(
      request,
      "/research/topics",
      "GET",
    )
    yield put(RESEARCH_ACTIONS.getTopicsListSuccess(responseData));
  } catch (err) {}
}

function parseUrl() {
  let obj = {}
  let array = this.props.history.location.search.split('?')
  for (let i = 1; i < array.length; i++) {
    let split = array[i].split('=')
    obj[split[0].trim()] = decodeURI(split[1].trim())
  }
  return obj
}

function* getArticlesByCategory(action) {
  try {
    let obj = {}
    let array = history.location.search.split('?')
    for (let i = 1; i < array.length; i++) {
      let split = array[i].split('=')
      obj[split[0].trim()] = decodeURI(split[1].trim())
    }
    let category
    let topic = false
    let offset = obj.page ? (obj.page - 1) * 10 : 0
    if (obj.section === 'Discover') {
      category = `research/feed?offset=${offset}`
    } else if (obj.section === 'Trending Reports') {
      category = `research/trendingReports?offset=${offset}`
    } else if (obj.section === 'My Bookmarks') {
      category = `research/bookmarks?offset=${offset}`
    } else if (obj.section === 'My Upvotes') {
      category = `research/upvotes/user?offset=${offset}`
    } else if (obj.section === 'My Downvotes') {
      category = `research/downvotes/user?offset=${offset}`
    } else if (obj.section === 'My Channel') {
      category = `research/userFeed?offset=${offset}`
    } else {
      topic = true
      category = `research/search?topic=${obj.section}&offset=${offset}`
    }
    let responseData = yield call(request, category, "GET", null, true)

    if (obj.category || obj.industry || obj.from || obj.to || obj.query) {
      const from = obj.from ? new Date(obj.from).toISOString() : ''
      const to = obj.to ? new Date(obj.to).toISOString() : ''
      let section = 'discover'
      if (obj.section === 'Trending Reports') {
        section = 'trending'
      } else if (obj.section === 'My Channel') {
        section = 'userFeed'
      } else if (obj.section === 'Bookmarked') {
        section = 'bookmarks'
      } else if (obj.section === 'My Upvotes') {
        section = 'upvotes'
      } else if (obj.section === 'My Downvotes') {
        section = 'downvotes'
      } else {
        section = 'discover'
      }
      let url
      if (topic) {
        url = `research/search?industry=${obj.industry || ''}&category=${obj.category || ''}&from=${from}&to=${to}&q=${obj.query || ''}&topic=${obj.section}`
      } else {
        url = `research/search?industry=${obj.industry || ''}&category=${obj.category || ''}&from=${from}&to=${to}&q=${obj.query || ''}&section=${section}`
      }
      responseData = yield call(request, url, "GET", null, true)
    }
    yield put(RESEARCH_ACTIONS.getArticlesByCategorySuccess(responseData))
  } catch (err) {}
}

function* searchArticles(action) {
  try {
    const { query, category, industry, to, from } = action.parameters;
    let url = `research/search?industry=${industry}&category=${category}&from=${
      from ? from.toISOString() : ""
    }&to=${to ? to.toISOString() : ""}&q=${query}`;
    const responseData = yield call(request, url, "GET", null, true);
    yield put(RESEARCH_ACTIONS.getArticlesByCategorySuccess(responseData));
  } catch (err) {}
}

function* unpublishReport(action) {
  try {
    const body = { _id: action.id, userId: action.userId }

    const responseData = yield call(
      request,
      '/research/contributor/unpublish',
      'POST',
      body,
    )

    yield put(ADMIN_ACTIONS.searchReportsRequest(action.params))
  } catch (err) {
    toast.error(err.reason)
  }
}

function* submitReport(action) {
  try {
    const { data } = action
    const responseData = yield call(
      request,
      'analytics/event',
      'POST',
      data,
      true,
    )
  } catch (err) {}
}

function* getReportList(action) {
  try {
    const offset = action.offset || 0
    const responseData = yield call(
      request,
      `admin/spamList?offset=${offset}`,
      'GET',
      null,
      true,
    )
    yield put(RESEARCH_ACTIONS.getReportListSuccess(responseData))
  } catch (err) {}
}

function* getAssetProfiles(action) {
  try {
    const { userId } = action
    const responseData = yield call(
      request,
      `profiles/byUser?userId=${userId}`,
      'GET',
      null,
      true,
    )
    yield put(RESEARCH_ACTIONS.getAssetProfilesSuccess(responseData))
  } catch (err) {
    yield put(RESEARCH_ACTIONS.getAssetProfilesError(err))
  }
}

function* getAssetResearch(action) {
  try {
    const responseData = yield call(
      request,
      `/research/search?q=${action.params}`,
      'GET'
    )

    yield put(RESEARCH_ACTIONS.getAssetResearchSuccess(responseData))
  } catch (err) {
    toast.error(err.reason)
    yield put(RESEARCH_ACTIONS.getAssetResearchError(err))
  }
}

function* getAssetPricingData(action) {
  try {
    const responseData = yield call(
      request,
      `/profiles/pricing?symbol=BTC`,
      'GET'
    )

    yield put(RESEARCH_ACTIONS.getAssetPricingDataSuccess(responseData))
  } catch (err) {
    toast.error(err.reason)
    yield put(RESEARCH_ACTIONS.getAssetPricingDataError(err))
  }
}

export default function* researchsSaga() {
  yield takeLatest(CONSTANTS.GET_RESEARCH_FEED_REQUEST, getResearchFeeds);
  yield takeLatest(CONSTANTS.GET_FILTER_RESEARCH_REQEUST, filterSearch);
  yield takeLatest(CONSTANTS.GET_UP_VOTES_REQUEST, getUpvotes);
  yield takeLatest(CONSTANTS.GET_DOWN_VOTES_REQUEST, getDownvotes);
  yield takeLatest(CONSTANTS.POST_UP_VOTE_REQUEST, postUpvote);
  yield takeLatest(CONSTANTS.POST_DOWN_VOTE_REQUEST, postDownvote);
  yield takeLatest(CONSTANTS.REMOVE_UP_VOTE_REQUEST, removeUpvote);
  yield takeLatest(CONSTANTS.REMOVE_DOWN_VOTE_REQUEST, removeDownvote);
  yield takeLatest(CONSTANTS.GET_REPORT_VOTES_USER_REQUEST, getReportVoteUsers);
  yield takeLatest(CONSTANTS.GET_DRAFT_REQUEST, getReport);
  yield takeLatest(CONSTANTS.GET_USERS_DRAFTS_REQUEST, getUsersDrafts);
  yield takeLatest(CONSTANTS.CREATE_DRAFT_REQUEST, createDraft);
  yield takeLatest(CONSTANTS.SAVE_DRAFT_REQUEST, saveDraft);
  yield takeLatest(CONSTANTS.DELETE_DRAFT_REQUEST, deleteDraft);
  yield takeLatest(CONSTANTS.PUBLISH_ARTICLE_REQUEST, publishArticle);
  yield takeLatest(CONSTANTS.GET_BOOKMARKS_REQUEST, getBookmarks);
  yield takeLatest(CONSTANTS.GET_TRENDING_ARTICLES_REQUEST,getTrendingArticles);
  yield takeLatest(CONSTANTS.GET_TRENDING_TOPICS_REQUEST, getTrendingTopics);
  yield takeLatest(CONSTANTS.GET_RESEARCH_USER_FEED_REQUEST,getResearchUserFeed);
  yield takeLatest(CONSTANTS.READ_ARTICLE_REQUEST, readArticle);
  yield takeLatest(CONSTANTS.GET_RELATED_ARTICLES_REQUEST, getRelatedArticles);
  yield takeLatest(CONSTANTS.POST_COMMENT_REQUEST, postComment);
  yield takeLatest(CONSTANTS.GET_ARTICLE_COMMENTS_REQUEST, getComments);
  yield takeLatest(CONSTANTS.ARTICLE_IMAGE_UPLOAD_REQUEST, articleImageUpload);
  yield takeLatest(CONSTANTS.ARTICLE_UPLOAD_DOCUMENT_REQUEST, uploadDocument);
  yield takeLatest(CONSTANTS.GET_TOPICS_LIST_REQUEST, getTopicsList);
  yield takeLatest(CONSTANTS.GET_ARTICLES_BY_CATEGORY_REQUEST,getArticlesByCategory);
  yield takeLatest(CONSTANTS.SEARCH_ARTICLES_REQUEST, searchArticles);
  yield takeLatest(CONSTANTS.UNPUBLISH_REPORT_REQUEST, unpublishReport);
  yield takeLatest(CONSTANTS.REPORT_SEARCH_REQUEST, reportSearchRequest);
  yield takeLatest(CONSTANTS.SUBMIT_REPORT_REQUEST, submitReport)
  yield takeLatest(CONSTANTS.GET_REPORT_LIST_REQUEST, getReportList)
  yield takeLatest(CONSTANTS.GET_ASSET_PROFILES_REQUEST, getAssetProfiles)
  yield takeLatest(CONSTANTS.GET_ASSET_RESEARCH_REQUEST, getAssetResearch)
  yield takeLatest(CONSTANTS.GET_ASSET_PRICING_DATA_REQUEST, getAssetPricingData)
}
