import React, { Component } from 'react'
import {
  Row,
  Col,
  Button,
  Image,
  Container,
  Card,
  Jumbotron,
  Modal,
  Spinner,
  OverlayTrigger,
  Tooltip,
  NavLink,
  Table,
} from 'react-bootstrap'
import Paginations from "../../Pagination"
import ReactHtmlParser from "react-html-parser"
import Iframe from "react-iframe";
import YouTube from 'react-youtube'
import he from 'he'
import { MdLocationOn } from 'react-icons/md'
import { IoIosHeartEmpty, IoIosHeart, IoMdPeople } from "react-icons/io"
import MetaTags from 'react-meta-tags'
import { FaFacebookF, FaTwitter, FaLinkedinIn, FaMediumM, FaGlobe, FaIndustry, FaMapMarkerAlt, FaTelegram, FaFileSignature, FaCalendarAlt, FaCheckCircle } from "react-icons/fa"
import { AiOutlineUser } from 'react-icons/ai'
import LogoImg from '../../../images/logoImage.js'
import Scrollspy from 'react-scrollspy'
import LightWeightChart from '../../LightWeightChart'

class UserTokenDetailPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      asset: this.props.asset,
      news: this.props.news,
      research: this.props.research,
      pricing: this.props.pricing,
      currentPosition: 'Profile Information',
    }
  }

  componentDidMount() {
    this.handleGetAssetAndInfo()
  }

  componentWillReceiveProps(nextProps) {
    if (this.props !== nextProps) {
      this.setState({
        asset: nextProps.asset,
        news: nextProps.news,
        research: nextProps.research,
        pricing: nextProps.pricing,
      })
    }
  }

  handleGetAssetAndInfo = () => {
    const assetName = this.props.match.params.asset_name
    this.props.userViewAssetRequest(assetName)
  }

  handleChangeCurrentPosition = position => this.setState({ currentPosition: position })

  handleMapThroughSections = () => {
    const { sections } = this.state.asset.data
    return sections.map(section => this.renderIndividualSection(section))
  }

  handleMapThroughSectionsForList = () => {
    const { sections } = this.state.asset.data
    return sections.map(section => this.renderIndividualSectionForList(section))
  }

  formatDate = dateInput => {
    let date = new Date(dateInput)
    const month = date.toLocaleString("en-us", { month: "short" })
    const day = date.getDate()
    const year = date.getFullYear()
    return month + " " + day + ", " + year
  }

  handleCurrentPosition = () => {
    const { currentPosition } = this.state

    let innerContent
    if (currentPosition === 'Profile Information') innerContent = this.renderProfileInformation()
    else if (currentPosition === 'News') innerContent = this.renderNews()
    else if (currentPosition === 'Research') innerContent = this.renderResearch()
    else if (currentPosition === 'Pricing Data') innerContent = this.renderPricingData()
    else if (currentPosition === 'Offering') innerContent = this.renderOffering()

    return innerContent
  }

  handleNewsArticles = () => {
    const { searchList, loaded, loading } = this.state.news

    let content
    if (loading) {
      content = this.renderLoading()
    } else if (loaded) {
      if (searchList.total > 0) {
        content = searchList.docs.map(article => this.renderIndividualArticle(article))
      }
    }

    return content
  }

  /*****************************************************************************/

  renderAsset = () => {
    const { data, loaded, loading } = this.state.asset

    let content
    if (loaded) {
      content = (
        <Container fluid className='asset-profile-container'>
          {this.renderHeaderSection()}
          {this.renderNavigationBar()}
          {this.handleCurrentPosition()}
        </Container>
      )
    } else if (loading) {
      content = this.renderLoading()
    }

    return content
  }

  renderLoading = () => {
    return (
      <Row noGutters>
        <Spinner animation='border' variant='light' />
      </Row>
    )
  }

  renderHeaderSection = () => {
    const {
      _id,
      name,
      image,
      location,
      industry,
      description,
      tradeLink,
      offering
    } = this.state.asset.data

    const briefContent = description

    let test
    if (this.state.asset.data.offering) {
      test = this.state.asset.data.offering
    } else {
      test = {}
    }
    const { pending_nda, nda_upload, pending_subscriber, subscriber_upload, completed_order, order_upload } = test

    let linkToTrade
    if (tradeLink) {
      linkToTrade = (
        <NavLink className='button-navlink' to={tradeLink}>
          <Button className='teal-button' size='sm'>
            Trade
          </Button>
        </NavLink>
      )
    }

    return (
      <Row noGutters className='utdp-header-section'>
        <Col>
          <Row noGutters className='utdp-header-name'>
            {name}
            {linkToTrade}
          </Row>
          <Row noGutters className='utdp-header-desc'>
            {description}
          </Row>
          <Row noGutters className='utdp-header-info-row'>
            <Col className='utdp-header-info'>
              <Row noGutters>
                <FaMapMarkerAlt className='utdp-header-info-icon' />
                {location}
              </Row>
            </Col>
            <Col className='utdp-header-info'>
              <Row noGutters>
                <FaIndustry className='utdp-header-info-icon' />
                {industry || 'Industry'}
              </Row>
            </Col>
          </Row>
          <Row noGutters className='utdp-header-offering-row'>
            <div>
              <label className='utdp-header-offering-label'>
                PENDING NDA APPROVALS
              </label>
              <Row noGutters className='utdp-header-info-row'>
                <label className='utdp-header-data'>
                  {pending_nda}
                </label>
                <a className='utdp-header-link' href={nda_upload} target='_blank'>
                  View
                </a>
              </Row>
            </div>
            <div>
              <label className='utdp-header-offering-label'>
                PENDING SUBSCRIBER AGREEMENTS
              </label>
              <Row noGutters className='utdp-header-info-row'>
                <label className='utdp-header-data'>
                  {pending_subscriber}
                </label>
                <a className='utdp-header-link' href={subscriber_upload} target='_blank'>
                  View
                </a>
              </Row>
            </div>
            <div>
              <label className='utdp-header-offering-label'>
                COMPLETED ORDERS
              </label>
              <Row noGutters className='utdp-header-info-row'>
                <label className='utdp-header-data'>
                  {completed_order}
                </label>
                <a className='utdp-header-link' href={order_upload} target='_blank'>
                  View
                </a>
              </Row>
            </div>
          </Row>
        </Col>
        <Col sm={3} className='utdp-token-image-container'>
          <Image src={image} className='utdp-token-image' />
        </Col>
      </Row>
    )
  } // end of renderHeaderSection()

  // FaFileSignature, FaCalendarAlt, FaCheckCircle

  renderNavigationBar = () => {
    const { currentPosition } = this.state

    let pricingData
    if (this.state.asset.data.tradedSymbol) {
      pricingData = (
        <Col
          className='utdp-nav-item'
          name='Pricing Data'
          onClick={() => this.handleChangeCurrentPosition('Pricing Data')}
          id={currentPosition === 'Pricing Data' ? 'current-position' : ''}>
          <p>Pricing Data</p>
        </Col>
      )
    }
    return (
      <Row noGutters className='utdp-nav-bar'>
        <Col
          className='utdp-nav-item'
          name='Profile Information'
          onClick={() => this.handleChangeCurrentPosition('Profile Information')}
          id={currentPosition === 'Profile Information' ? 'current-position' : ''}>
          <p>Profile Information</p>
        </Col>
        <Col
          className='utdp-nav-item'
          name='News'
          onClick={() => this.handleChangeCurrentPosition('News')}
          id={currentPosition === 'News' ? 'current-position' : ''}>
          <p>News</p>
        </Col>
        <Col
          className='utdp-nav-item'
          name='Research'
          onClick={() => this.handleChangeCurrentPosition('Research')}
          id={currentPosition === 'Research' ? 'current-position' : ''}>
          <p>Research</p>
        </Col>
        {pricingData}
        <Col
          className='utdp-nav-item'
          name='Offering'
          onClick={() => this.handleChangeCurrentPosition('Offering')}
          id={currentPosition === 'Offering' ? 'current-position' : ''}>
          <p>Offering</p>
        </Col>
      </Row>
    )
  }

  renderProfileInformation = () => {
    const { sections } = this.state.asset.data
    const scrollspySections = sections.map(section => this.renderIndividualSectionForList(section))
    let scrollspyItems = []
    for (let i = 0; i < sections.length; i++) {
      scrollspyItems.push(sections[i].name)
    }

    return (
      <Row noGutters className='utdp-profile-info'>
        <div className='utdp-profile-sections-container'>
          <Row noGutters className='utdp-profile-sections-header'>
            Profile Sections
          </Row>
          <Row noGutters>
            <Scrollspy
              items={scrollspyItems}
              currentClassName='is-current-profile-section'
              className='profile-scrollspy'>
              {scrollspySections}
            </Scrollspy>
          </Row>
        </div>
        <Col>
          {this.handleMapThroughSections()}
        </Col>
      </Row>
    )
  }

  renderIndividualSectionForList = section => {
    const { name } = section
    return (
      <a className='individual-section-for-scrollspy'
        id={name + ' section-for-list'} href={`#${name}`}>
        {name}
      </a>
    )
  }

  renderIndividualSection = section => {
    const { name, fields, display } = section

    if (display == 'List') {
      return (
        <Row noGutters className='utdp-individual-section' key={name}>
          <div className='utdp-anchor' id={name} />
          <Col>
            <h3>{name}</h3>
            <Row noGutters className='utdp-fields-row'>
              {fields.map(field => this.renderIndividualField(field))}
            </Row>
          </Col>
        </Row>
      )
    } else if (display == 'Tile') {
      return (
        <Row noGutters className='utdp-individual-section' key={name}>
          <div className='utdp-anchor' id={name} />
          <Col>
            <Row noGutters className='utdp-section-tile-row'>
              <h3>{name}</h3>
            </Row>
            <Row noGutters className='utdp-fields-row'>
              {fields.map(field => this.renderIndividualTileField(field))}
            </Row>
          </Col>
        </Row>
      )
    }
  }

  renderIndividualField = field => {
    const { fieldName, fieldType, value, displayText, fields, subfields } = field
    const key = fieldName + ' ' + value

    let fieldRender
    if (fieldType === 'date') {
      fieldRender = <p>{this.formatDate(value)}</p>
    } else if (fieldType === 'image') {
      fieldRender = this.renderFieldImage(value)
    } else if (fieldType == 'video') {
      fieldRender = this.renderVideo(value)
    } else if (fieldType == 'document') {
      fieldRender = this.renderDocument(value)
    } else if (fieldType == 'social') {
      fieldRender = this.renderSocial(value, displayText)
    } else if (fieldType == 'link') {
      fieldRender = this.renderLink(value, displayText)
    } else if (fieldType == 'website') {
      fieldRender = <a target='_blank' href={value}>Link</a>
    } else if (fieldType == 'object') {
      fieldRender = this.renderObject(subfields)
    } else {
      fieldRender = <div className='utdp-individual-field-text-content'>{value}</div>
    }

    let hidden = ''
    if (!fieldName || !value) hidden = 'hidden'

    return (
      <Row noGutters className='utdp-individual-field' key={key} id={hidden}>
        <label className='utdp-individual-field-label'>
          {fieldName}
        </label>
        <Col>
          {fieldRender}
        </Col>
      </Row>
    )
  }

  renderIndividualTileField = field => {
    const { fieldName, fieldType, value, displayText, platform, fields, subfields } = field
    const key = fieldName + ' ' + value

    let fieldRender
    if (fieldType === 'date') {
      fieldRender = <p>{this.formatDate(value)}</p>
    } else if (fieldType === 'image') {
      fieldRender = this.renderFieldImage(value)
    } else if (fieldType == 'video') {
      fieldRender = this.renderVideo(value)
    } else if (fieldType == 'document') {
      fieldRender = this.renderDocument(value)
    } else if (fieldType == 'social') {
      fieldRender = this.renderSocial(value, platform)
    } else if (fieldType == 'link') {
      fieldRender = this.renderLink(value, displayText)
    } else if (fieldType == 'website') {
      fieldRender = <a target='_blank' href={value}>Link</a>
    } else if (fieldType == 'object') {
      fieldRender = this.renderObject(subfields)
    } else {
      fieldRender = <p>{value}</p>
    }

    let hidden = ''
    if (!fieldName || !value) hidden = 'hidden'

    return (
      <div className='utdp-individual-tile-field' key={key} id={hidden}>
        <label>{fieldName}</label>
        <Row noGutters className='utdp-tile-row'>
          {fieldRender}
        </Row>
      </div>
    )
  }

  renderLink = (value, displayText) => {
    return (
      <Row noGutters className='utdp-form-link-row'>
        <a href={value} target='_blank'>
          {displayText}
        </a>
      </Row>
    )
  }

  renderSocial = (value, platform) => {
    let icon
    if (platform == 'Website') icon = <FaGlobe />
    else if (platform == 'Twitter') icon = <FaTwitter />
    else if (platform == 'LinkedIn') icon = <FaLinkedinIn />
    else if (platform == 'Facebook') icon = <FaFacebookF />
    else if (platform == 'Telegram') icon = <FaTelegram />
    else if (platform == 'Medium') icon = <FaMediumM />

    return (
      <Row noGutters className='utdp-social-icon-row'>
        <a href={value} target='_blank' className='utdp-social-icon-link'>
          {icon}
        </a>
      </Row>
    )
  }

  renderDocument = doc => {
    return (
      <Row noGutters className='utdp-doc-row'>
        <a target='_blank' href={doc}>View Document</a>
      </Row>
    )
  }

  renderVideo = video => {
    let videoLink
    if (video) {
      if (video.includes('embed')) {
        videoLink = video
      } else if (video.includes('watch?v=')) {
        const newLink = video.replace('watch?v=', 'embed/')
        videoLink = newLink
      }

      return (
        <Row noGutters className='utdp-image-row'>
          <Iframe
            url={videoLink}
            className='utdp-video' />
        </Row>
      )
    }
  }

  renderFieldImage = image => {
    return (
      <Row noGutters className='utdp-image-row'>
        <Image
          onClick={() => this.handleOpenImageInNewTab(image)}
          src={image} className='utdp-image' />
      </Row>
    )
  }

  renderObject = subfields => {
    return (
      <Row noGutters className='utdp-subfields-row'>
        <Col>
          {subfields.map(subfield => this.renderObjectSubfield(subfield))}
        </Col>
      </Row>
    )
  }

  renderObjectSubfield = subfield => {
    const { fieldType, fieldName, value, displayText, platform } = subfield
    let fieldRender
    if (fieldType === 'date') {
      fieldRender = <p>{this.formatDate(value)}</p>
    } else if (fieldType === 'image') {
      fieldRender = this.renderFieldImage(value)
    } else if (fieldType == 'video') {
      fieldRender = this.renderVideo(value)
    } else if (fieldType == 'document') {
      fieldRender = this.renderDocument(value)
    } else if (fieldType == 'social') {
      fieldRender = this.renderSocial(value, platform)
    } else if (fieldType == 'link') {
      fieldRender = this.renderLink(value, displayText)
    } else if (fieldType == 'website') {
      fieldRender = <a target='_blank' href={value}>Link</a>
    } else {
      fieldRender = <p>{value}</p>
    }

    return (
      <Row noGutters className='utdp-subfield-row'>
        {fieldRender}
      </Row>
    )
  }

  handleOpenImageInNewTab = imageUrl => {
    window.open(imageUrl)
  }

  renderNews = () => {
    const { searchList, loaded, loading } = this.state.news

    let content
    if (loaded) content = this.handleNewsArticles()
    else if (loading) content = this.renderLoading()

    return (
      <Row noGutters className='utdp-profile-info'>
        <Col>
          <Row noGutters className='utdp-news-row'>
            <div className='utdp-news-container'>
              {content}
            </div>
          </Row>
          <Row noGutters className='utdp-see-more-row'>
            <NavLink href={`/news?name=${this.state.asset.data.name}`}>
              Explore Our Full News Library
            </NavLink>
          </Row>
        </Col>
      </Row>
    )
  }

  renderIndividualArticle = article => {
    const {
      date,
      author,
      link,
      id,
      title,
      content
    } = article

    const returnDate = this.formatDate(date)

    let newContent = ReactHtmlParser(content)
    if (newContent.length > 200) {
      newContent = newContent.splice(0, 200) + '...'
    }

    return (
      <Row noGutters className='utdp-individual-article'>
        <Col>
          <a href={link} target='_blank'>
            <h4 className='utdp-article-title'>{title}</h4>
          </a>
          <div className='utdp-article-info'>
            {returnDate} | {author}
          </div>
          <a href={link} target='_blank' className='utdp-article-link'>
            Read More
          </a>
        </Col>
      </Row>
    )
  }

  renderResearch = () => {
    const { data, loading, loaded } = this.state.research

    let content
    if (loaded) {
      if (data.total > 0) {
        content = data.docs.map(research => this.renderIndividualResearch(research))
      }
    } else if (loading) {
      content = this.renderLoading()
    }

    return (
      <Row noGutters className='utdp-profile-info'>
        <Col>
          <Row noGutters className='utdp-news-row'>
            <div className='utdp-news-container'>
              {content}
            </div>
          </Row>
          <Row noGutters className='utdp-see-more-row'>
            <NavLink href={`/research?name=${this.state.asset.data.name}`}>
              Explore Our Full Research Library
            </NavLink>
          </Row>
        </Col>
      </Row>
    )
  }

  renderIndividualResearch = research => {
    const { _id, image, title, user_id, datePublished, summary } = research

    let displaySummary
    if (summary && summary.length > 220) {
      displaySummary = summary.slice(0, 220) + '...'
    } else {
      displaySummary = summary
    }

    let userImage
    if (user_id.image) {
      userImage = <Image src={user_id.image} className='utdp-user-image' />
    } else {
      userImage = <AiOutlineUser className='utdp-user-image-icon' />
    }

    const userName = user_id.name ? user_id.name : user_id._id

    const navName = title.replace(/\s+/g, '-').toLowerCase() + '-' + _id
    const link = `/research/${navName}`

    const returnDate = this.formatDate(datePublished)

    return (
      <Row noGutters className='utdp-individual-article' key={_id}>
        <Col>
          <a href={link} target='_blank'>
            <h4 className='utdp-article-title'>{title}</h4>
          </a>
          <div className='utdp-article-info'>
            {userImage} {userName} | {returnDate}
          </div>
          <div className='utdp-article-summary'>
            {displaySummary}
          </div>
          <a href={link} target='_blank' className='utdp-article-link'>
            Read More
          </a>
        </Col>
        <div className='utdp-article-image-container'>
          <a href={link} target='_blank'>
            <Image src={image} className='discover-article-image' />
          </a>
        </div>
      </Row>
    )
  }

  renderPricingData = () => {
    const { data, loaded, loading } = this.state.pricing

    let content
    if (loaded) {
      const { price, volume, high, low } = data
      content = (
        <Row noGutters>
          <Col>
            <Row noGutters className='light-chart-info-row'>
              <div className='light-chart-info-label'>
                Price: {price}
              </div>
              <div className='light-chart-info-label'>
                Volume: {volume}
              </div>
              <div className='light-chart-info-label'>
                High: {high}
              </div>
              <div className='light-chart-info-label'>
                Low: {low}
              </div>
            </Row>
            <Row noGutters className='light-chart-row'>
              <div className='light-chart-container'>
                <LightWeightChart data={data} />
              </div>
            </Row>
          </Col>
        </Row>
      )
    } else if (loading) {
      content = this.renderLoading()
    }

    return content
  }

  renderComingSoon = () => {
    return (
      <Row noGutters className='utdp-coming-soon'>
        Coming soon!
      </Row>
    )
  }

  renderOffering = () => {
    const { data, loaded, loading } = this.state.asset

    let content
    if (loaded) {
      const { days_left, individual_confirmed, individual_ioi, individual_orders,institutional_confirmed, institutional_ioi, institutional_orders, percent_filled, total_amount, total_confirmed, total_ioi, total_offered, total_orders } = data.offering
      const profileName = data.name
      content = (
        <Row noGutters>
          <Col>
            <Row noGutters className='utdp-offering-row'>
              <Col>
              <div className="utdp-offering-wrapper">
                <Row noGutters className='utdp-offering-header-row'>
                  Offering Status
                </Row>
                <Row noGutters className='utdp-offering-table-row'>
                  <div className='utdp-table-title utdp-table-profile-name'>
                    {profileName}
                  </div>
                  <Col>
                    <Table className='utdp-offering-table'>
                      <thead>
                        <tr>
                          <th>Total Offering</th>
                          <th>Percent of Capital Filled</th>
                          <th>Days Left</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <td>$ {total_offered}</td>
                          <td>{percent_filled} %</td>
                          <td>{days_left}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </Col>

                </Row>
                </div>
              </Col>
            </Row>
            <Row noGutters className='utdp-offering-row'>
              <Col>
              <div className="utdp-offering-wrapper">
                <Row noGutters className='utdp-offering-header-row'>
                  Offering Breakdown
                </Row>
                <Row noGutters className='utdp-offering-table-row'>
                  <Table bordered className='utdp-offering-table'>
                    <thead>
                      <tr>
                        <th>Total Amount</th>
                        <th>Total IOI</th>
                        <th>Total Confirmed</th>
                        <th>Total Orders</th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr>
                        <td>$ {total_amount}</td>
                        <td>$ {total_ioi}</td>
                        <td>$ {total_confirmed}</td>
                        <td>{total_orders}</td>
                      </tr>
                    </tbody>
                  </Table>
                </Row>
                <Row noGutters className='utdp-offering-table-row'>
                  <div className='utdp-table-title'>
                    Institutional IOI's
                  </div>
                  <Col>
                    <Table bordered className='utdp-offering-table'>
                      <thead>
                        <tr>
                          <th>Institutional IOI</th>
                          <th>Institutional Confirmed</th>
                          <th>Institutional Orders</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <td>$ {institutional_ioi}</td>
                          <td>$ {institutional_confirmed}</td>
                          <td>{institutional_orders}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </Col>
                </Row>
                <Row noGutters className='utdp-offering-table-row'>
                  <div className='utdp-table-title'>
                    Individual IOI's
                  </div>
                  <Col>
                    <Table bordered className='utdp-offering-table'>
                      <thead>
                        <tr>
                          <th>Individual IOI</th>
                          <th>Individual Confirmed</th>
                          <th>Individual Orders</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <td>$ {individual_ioi}</td>
                          <td>$ {individual_confirmed}</td>
                          <td>{individual_orders}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </Col>
                </Row>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      )
    } else if (loading) {
      content = this.renderLoading()
    }

    return content
  }

  renderMetaTags = () => {
    const { data, loaded } = this.state.asset
    return (
      <div>
        <MetaTags>
          <title>{data.name || ''}</title>
          <meta name='description' content={data.description || ''}/>
          <meta name='title' property='og:title' content={(data.name || '') + ' ' + '- News, Information, Pricing, Research'}/>
          <meta property='og:description' content={data.description}/>
          <meta property='og:image' content={data.image || ''}/>
          <meta property='og:type' content='website'/>
          <meta property='og:url' content={window.location.href} />
          <meta property='twitter:title' content={(data.name || '') + ' ' + '- News, Information, Pricing, Research'}/>
          <meta property='twitter:description' content={data.description || ''}/>
          <meta property='twitter:image' content={data.image || ''}/>
          <meta property='twitter:site' content='Velocity Channel'/>
          <meta name='robots' content='nofollow' />
        </MetaTags>
      </div>
    )
  }

  render() {
    return (
      <Container fluid className='user-content-container'>
        {this.renderAsset()}
      </Container>
    )
  }
/************************* END OF RENDER FUNCTIONS ****************************/
}  // end of UserTokenDetailPage

export default UserTokenDetailPage
