import React, { Component } from 'react'
import {
  Container,
  Image,
  Row,
  Col,
} from "react-bootstrap";
import './NotFound.css';
import logo from '../../images/HomePage/FI_LOGO.png'
import { Link } from 'react-router-dom';

export default class NotFound extends Component {
  render() {
    const pathname = this.props.history.location.pathname
    const account = this.props.account
    let className='not-found-container'
    let content
    let innerContent
    if (account.authenticated) {
      // if this user is logged in
      if (account.data.isAdmin) {
        // if the user is an admin and logged in
        innerContent = (
          <React.Fragment>
            <Row noGutters className='not-found-header-row'>
              PAGE NOT FOUND
            </Row>
            <Row noGutters className='not-found-content-row'>
              The link you clicked may be broken or the page may have been removed.
            </Row>
            <Row noGutters className='not-found-links-row'>
              <p>VISIT THE</p>&nbsp;
              <Link to='/'>HOMEPAGE</Link>&nbsp;
              <p>OR</p>&nbsp;
              <Link to='/contact'>CONTACT US</Link>&nbsp;
              <p>ABOUT THE PROBLEM</p>
            </Row>
          </React.Fragment>
        )

      } else {
        // if the user is not an admin but logged in
        innerContent = (
          <Row noGutters className='not-found-content-row'>
            Your account does not have access to this feature. Please contact an administrator
          </Row>
        )
      }

    } else {
      if (pathname.includes('/admin')) {
        innerContent = (
          <Row noGutters className='not-found-content-row'>
            Please <Link className='not-found-link' to='/login'>Log in</Link> to access this feature
          </Row>
        )
        className = className + ' admin-type'
      } else {
        innerContent = (
          <React.Fragment>
            <Row noGutters className='not-found-header-row'>
              PAGE NOT FOUND
            </Row>
            <Row noGutters className='not-found-content-row'>
              The link you clicked may be broken or the page may have been removed
            </Row>
            <Row noGutters className='not-found-links-row'>
              <p>VISIT THE</p>&nbsp;
              <Link to='/'>HOMEPAGE</Link>&nbsp;
              <p>OR</p>&nbsp;
              <Link to='/contact'>CONTACT US</Link>&nbsp;
              <p>ABOUT THE PROBLEM</p>
            </Row>
          </React.Fragment>
        )
      }
    }

    return (
      <Container fluid className={className}>
        <div className='not-found-container-card'>
          <Row noGutters className='not-found-image-row'>
            <Image src={logo} className='not-found-image' />
          </Row>
          {innerContent}
        </div>
      </Container>
    )
  }
} // end of NotFound extends Component
