import React, { Fragment, PureComponent } from "react"
import { Switch, Route, withRouter } from "react-router-dom"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import {
  Button,
  Row,
  Col,
  Image,
  Nav,
  Modal,
  Container,
  Spinner,
} from "react-bootstrap"
import { NavLink } from 'react-router-dom'
import * as COMPONENT from '../containers'
import jwt from 'jsonwebtoken'
import { createBrowserHistory } from 'history';

class Routes extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      accountData: {
        authenticated: false,
        data: {},
        loaded: false,
        loading: false,
        error: ''
      },
    }
  }

  componentDidMount() {
    this.props.autoLoginRequest()
  }

  componentWillReceiveProps(nextProps) {
    if (this.props !== nextProps) {
      this.setState({
        accountData: nextProps.accountData
      })
    }
  }

  unauthenticatedRoutes = () => {
    return (
      <Switch onUpdate={window.scrollTo(0,0)}>
        <Route exact path='/' component={COMPONENT.SearchAssets} />
        <Route exact path='/login' component={COMPONENT.Login} />
        <Route exact path='/register' component={COMPONENT.Register} />
        <Route exact path="/contact" component={COMPONENT.Contact} />
        <Route exact path="/terms" component={COMPONENT.Terms} />
        <Route exact path="/about" component={COMPONENT.HomePage} />
        <Route exact path="/privacypolicy" component={COMPONENT.PrivacyPolicy} />
        <Route exact path="/forgot-password" component={COMPONENT.ForgotPassword} />
        <Route exact path="/account/reset" component={COMPONENT.AccountReset} />
        <Route exact path='/account/settings' component={COMPONENT.Login} />
        <Route exact path="/research" component={COMPONENT.Discover} />
        <Route exact path="/research/discover" component={COMPONENT.Discover} />
        <Route exact path="/research/drafts" component={COMPONENT.Login} />
        <Route exact path='/research/feed' component={COMPONENT.ResearchHomepage} />
        <Route exact path="/research/management" component={COMPONENT.Login} />
        <Route exact path="/become-a-contributor" component={COMPONENT.ResearchApplicationInformation} />
        <Route exact path="/research/apply" component={COMPONENT.ResearchApplication} />
        <Route exact path="/research/:article_id" component={COMPONENT.Article} />
        <Route exact path="/news" component={COMPONENT.News} />
        <Route exact path="/news/:news_tab" component={COMPONENT.News} />
        <Route exact path="/token" component={COMPONENT.TokenProfile} />
        <Route exact path="/assets/search-assets" component={COMPONENT.SearchAssets} />
        <Route exact path="/get-your-token-listed" component={COMPONENT.TokenListed} />
        <Route exact path="/assets/:asset_name" component={COMPONENT.UserTokenDetailPage} />
        <Route exact path="/contributor/:username" component={COMPONENT.ContributorProfile} />
        <Route component={COMPONENT.NotFound} />
      </Switch>
    )
  } // end of unauthenticatedRoutes()

  authenticatedRoutes = () => (
    <Switch onUpdate={window.scrollTo(0,0)}>
      <Route exact path="/" component={COMPONENT.SearchAssets} />
      <Route exact path='/login' component={COMPONENT.ResearchManagementPortal} />
      <Route exact path='/register' component={COMPONENT.ResearchManagementPortal} />
      <Route exact path="/contact" component={COMPONENT.Contact} />
      <Route exact path="/terms" component={COMPONENT.Terms} />
      <Route exact path="/about" component={COMPONENT.HomePage} />
      <Route exact path="/privacypolicy" component={COMPONENT.PrivacyPolicy} />
      <Route exact path="/forgot-password" component={COMPONENT.ForgotPassword} />
      <Route exact path="/account/reset" component={COMPONENT.AccountReset} />
      <Route exact path='/account/settings' component={COMPONENT.ResearchManagementPortal} />
      <Route exact path="/research" component={COMPONENT.Discover} />
      <Route exact path="/research/discover" component={COMPONENT.Discover} />
      <Route exact path="/research/drafts" component={COMPONENT.ResearchApplication} />
      <Route exact path="/research/management" component={COMPONENT.ResearchManagementPortal} />
      <Route exact path='/research/feed' component={COMPONENT.ResearchHomepage} />
      <Route exact path="/research/collections" component={COMPONENT.Collections} />
      <Route exact path="/research/collections/:collection_id" component={COMPONENT.Collections} />
      <Route exact path="/become-a-contributor" component={COMPONENT.ResearchApplicationInformation} />
      <Route exact path="/research/apply" component={COMPONENT.ResearchApplication} />
      <Route exact path="/research/:article_id" component={COMPONENT.Article} />
      <Route exact path="/news" component={COMPONENT.News} />
      <Route exact path="/news/:news_tab" component={COMPONENT.News} />
      <Route exact path="/token" component={COMPONENT.TokenProfile} />
      <Route exact path="/assets/search-assets" component={COMPONENT.SearchAssets} />
      <Route exact path="/get-your-token-listed" component={COMPONENT.TokenListed} />
      <Route exact path="/assets/:asset_name" component={COMPONENT.UserTokenDetailPage} />
      <Route exact path="/contributor/:username" component={COMPONENT.ContributorProfile} />
      <Route component={COMPONENT.NotFound} />
    </Switch>
  ) // end of authenticatedRoutes()

  adminRoutes = () => (
    <Switch onUpdate={window.scrollTo(0,0)}>
      <Route exact path="/" component={COMPONENT.SearchAssets} />
      <Route exact path='/login' component={COMPONENT.ResearchManagementPortal} />
      <Route exact path='/register' component={COMPONENT.ResearchManagementPortal} />
      <Route exact path="/contact" component={COMPONENT.Contact} />
      <Route exact path="/terms" component={COMPONENT.Terms} />
      <Route exact path="/about" component={COMPONENT.HomePage} />
      <Route exact path="/privacypolicy" component={COMPONENT.PrivacyPolicy} />
      <Route exact path="/forgot-password" component={COMPONENT.ForgotPassword} />
      <Route exact path="/account/reset" component={COMPONENT.AccountReset} />
      <Route exact path='/account/settings' component={COMPONENT.ResearchManagementPortal} />
      <Route exact path="/research" component={COMPONENT.Discover} />
      <Route exact path="/research/discover" component={COMPONENT.Discover} />
      <Route exact path="/research/drafts" component={COMPONENT.ResearchHomepage} />
      <Route exact path='/research/feed' component={COMPONENT.ResearchHomepage} />
      <Route exact path="/research/management" component={COMPONENT.ResearchHomepage} />
      <Route exact path="/research/collections" component={COMPONENT.Collections} />
      <Route exact path="/research/collections/:collection_id" component={COMPONENT.Collections} />
      <Route exact path="/become-a-contributor" component={COMPONENT.ResearchApplicationInformation} />
      <Route exact path="/research/apply" component={COMPONENT.ResearchApplication} />
      <Route exact path="/research/:article_id" component={COMPONENT.Article} />
      <Route exact path="/news" component={COMPONENT.News} />
      <Route exact path="/news/:news_tab" component={COMPONENT.News} />
      <Route exact path="/token" component={COMPONENT.TokenProfile} />
      <Route exact path="/assets/search-assets" component={COMPONENT.SearchAssets} />
      <Route exact path="/get-your-token-listed" component={COMPONENT.TokenListed} />
      <Route exact path="/assets/:asset_name" component={COMPONENT.UserTokenDetailPage} />
      <Route exact path="/contributor/:username" component={COMPONENT.ContributorProfile} />
      <Route path='/admin' component={COMPONENT.Admin} />
      <Route exact path='/account/settings' component={COMPONENT.ResearchManagementPortal} />
      <Route component={COMPONENT.NotFound} />
    </Switch>
  ) // end of adminRoutes()

  adminContributorRoutes = () => (
    <Switch onUpdate={window.scrollTo(0,0)}>
      <Route exact path="/" component={COMPONENT.SearchAssets} />
      <Route exact path='/login' component={COMPONENT.ResearchManagementPortal} />
      <Route exact path='/register' component={COMPONENT.ResearchManagementPortal} />
      <Route exact path="/contact" component={COMPONENT.Contact} />
      <Route exact path="/terms" component={COMPONENT.Terms} />
      <Route exact path="/about" component={COMPONENT.HomePage} />
      <Route exact path="/privacypolicy" component={COMPONENT.PrivacyPolicy} />
      <Route exact path="/forgot-password" component={COMPONENT.ForgotPassword} />
      <Route exact path="/account/reset" component={COMPONENT.AccountReset} />
      <Route exact path='/account/settings' component={COMPONENT.ResearchManagementPortal} />
      <Route exact path="/research" component={COMPONENT.Discover} />
      <Route exact path="/research/discover" component={COMPONENT.Discover} />
      <Route exact path="/research/drafts" component={COMPONENT.Research} />
      <Route exact path='/research/feed' component={COMPONENT.ResearchHomepage} />
      <Route exact path="/research/management" component={COMPONENT.ResearchManagementPortal} />
      <Route exact path="/research/collections" component={COMPONENT.Collections} />
      <Route exact path="/research/collections/:collection_id" component={COMPONENT.Collections} />
      <Route exact path="/become-a-contributor" component={COMPONENT.ResearchApplicationInformation} />
      <Route exact path="/research/apply" component={COMPONENT.ResearchApplication} />
      <Route exact path="/research/:article_id" component={COMPONENT.Article} />
      <Route exact path="/news" component={COMPONENT.News} />
      <Route exact path="/news/:news_tab" component={COMPONENT.News} />
      <Route exact path="/token" component={COMPONENT.TokenProfile} />
      <Route exact path="/assets/search-assets" component={COMPONENT.SearchAssets} />
      <Route exact path="/get-your-token-listed" component={COMPONENT.TokenListed} />
      <Route exact path="/assets/:asset_name" component={COMPONENT.UserTokenDetailPage} />
      <Route exact path="/contributor/:username" component={COMPONENT.ContributorProfile} />
      <Route path='/admin' component={COMPONENT.Admin} />
      <Route exact path='/account/settings' component={COMPONENT.ResearchManagementPortal} />
      <Route component={COMPONENT.NotFound} />
    </Switch>
  )

  contributorRoutes = () => (
    <Switch onUpdate={window.scrollTo(0,0)}>
      <Route exact path="/" component={COMPONENT.SearchAssets} />
      <Route exact path='/login' component={COMPONENT.ResearchManagementPortal} />
      <Route exact path='/register' component={COMPONENT.ResearchManagementPortal} />
      <Route exact path="/contact" component={COMPONENT.Contact} />
      <Route exact path="/terms" component={COMPONENT.Terms} />
      <Route exact path="/about" component={COMPONENT.HomePage} />
      <Route exact path="/privacypolicy" component={COMPONENT.PrivacyPolicy} />
      <Route exact path="/forgot-password" component={COMPONENT.ForgotPassword} />
      <Route exact path="/account/reset" component={COMPONENT.AccountReset} />
      <Route exact path='/account/settings' component={COMPONENT.ResearchManagementPortal} />
      <Route exact path="/research" component={COMPONENT.Discover} />
      <Route exact path="/research/discover" component={COMPONENT.Discover} />
      <Route exact path="/become-a-contributor" component={COMPONENT.ResearchApplicationInformation} />
      <Route exact path="/research/apply" component={COMPONENT.ResearchApplication} />
      <Route exact path="/research/drafts" component={COMPONENT.Research} />
      <Route exact path='/research/feed' component={COMPONENT.ResearchHomepage} />
      <Route exact path="/research/management" component={COMPONENT.ResearchManagementPortal} />
      <Route exact path="/research/collections" component={COMPONENT.Collections} />
      <Route exact path="/research/collections/:collection_id" component={COMPONENT.Collections} />
      <Route exact path="/news" component={COMPONENT.News} />
      <Route exact path="/news/:news_tab" component={COMPONENT.News} />
      <Route exact path="/token" component={COMPONENT.TokenProfile} />
      <Route exact path="/assets/search-assets" component={COMPONENT.SearchAssets} />
      <Route exact path='/get-your-token-listed' component={COMPONENT.TokenListed} />
      <Route exact path="/assets/:asset_name" component={COMPONENT.UserTokenDetailPage} />
      <Route exact path="/research/:article_id" component={COMPONENT.Article} />
      <Route exact path="/contributor/:username" component={COMPONENT.ContributorProfile} />
      <Route component={COMPONENT.NotFound} />
    </Switch>
  ) // end of contributorRoutes()

  renderRoutesBasedOnUserStatus = () => {
    const { data, authenticated } = this.state.accountData

    if (authenticated) {
      if (data.isAdmin && data.isContributor === true) {
        // if user is an admin & contributor
        console.log('adminContributorRoutes')
        return this.adminContributorRoutes()

      } else if (data.isContributor === true) {
        // if user is a contributor
        console.log('contributorRoutes')
        return this.contributorRoutes()

      } else if (data.isAdmin) {
        // if user is an admin
        console.log('adminRoutes')
        return this.adminRoutes()

      } else {
        // if user has an accessToken (logged in)
        console.log('authenticatedRoutes')
        return this.authenticatedRoutes()

      }
    } else {
      // everything else
      console.log('unauthenticatedRoutes')
      return this.unauthenticatedRoutes()

    }
  } // end of renderRoutesBasedOnUserStatus()

  renderLoginModal = () => {
    return (
      <Modal
        show={this.state.showLoginModalStatus}
        className='login-modal'
        onHide={this.handleCloseModal}>
        <Modal.Header closeButton />
        <Modal.Body>
          <Row className='no-padding'>
            <div className='login-modal-header'>
              Create an account to engage with Velocity Channel content, follow content contributors, create personalized lists of research, and more!
            </div>
          </Row>
          <Row>
            <Button
              className='sign-up-button'
              onClick={e => this.handleModalButton(e, '/register')}>
              Sign Up
            </Button>
          </Row>
          <Row className='no-padding'>
            <div className='login-modal-bottom-text'>
              Already have an account?
              <div className='login-link' onClick={e => this.handleModalButton(e, '/login')}>
                Sign in
              </div>
            </div>
          </Row>
        </Modal.Body>
      </Modal>
    )
  }

  renderPane = () => {
    const currentLocation = this.props.history.location
    const { loading, loaded, error } = this.state.accountData

    let content
    if (loading) {
      content = (
        <Container fluid className='app-container'>
          <Row noGutters className='app-row'>
            <Col className='content-pane'>
              <Row noGutters className='test-row'>
                {this.renderLoading()}
              </Row>
            </Col>
          </Row>
        </Container>
      )
    } else {
      if (currentLocation.pathname.includes('/admin')) {
        content = (
          <Container fluid className='app-container'>
            {this.renderRoutesBasedOnUserStatus()}
          </Container>
        )
      } else if (currentLocation.pathname.includes('/login') || currentLocation.pathname.includes('/register') || currentLocation.pathname.includes('/forgot')) {
        content = (
          <Container fluid className='app-container'>
            <Row noGutters className='app-row'>
              <Col>
                {this.renderRoutesBasedOnUserStatus()}
                <COMPONENT.Footer />
              </Col>
            </Row>
          </Container>
        )
      } else {
        content = (
          <Container fluid className='app-container'>
            <Row noGutters className='app-row'>
              <Col>
                <COMPONENT.Topbar accountData={this.state.accountData} />
                {this.renderRoutesBasedOnUserStatus()}
                <COMPONENT.Footer />
              </Col>
            </Row>
          </Container>
        )
      }
    }

    return content
  }

  renderLoading = () => (
    <Spinner className='render-spinner' animation='border' />
  )

  render() {
    return (
      <Fragment>
        <div className='app-content'>
          <ToastContainer hideProgressBar={true} autoClose={3000} />
          {this.renderPane()}
        </div>
      </Fragment>
    )
  }
}

export default withRouter(Routes)
