import React, { Component } from 'react';
import { Button, Form, Row, Col, Jumbotron } from 'react-bootstrap';

class ForgotPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
    };
  }

  componentWillUnmount() {
    this.props.resetForgotPassword();
  }

  handleFormChange = fieldName => e => {
    this.setState({
      [fieldName]: e.target.value,
    });
  };

  handleSubmit = e => {
    e.preventDefault();

    const { email } = this.state;
    const { forgotPasswordRequest } = this.props;

    forgotPasswordRequest(email);
  };

  renderForgotPasswordForm = () => (
    <React.Fragment>
      <p>Please enter an email and we will send you a reset link:</p>
      <Form onSubmit={this.handleSubmit}>
        <Form.Group controlId="formBasicEmail">
          <Form.Control
            placeholder="Email address..."
            type="email"
            onChange={this.handleFormChange('email')}
            required
            className='forgot-password-form-control'
          />
          <Form.Text className="text-muted" />
        </Form.Group>
        <Row className='no-padding forgot-password-button-row'>
          <Button
            variant="primary"
            className="forgot-password-button"
            type="submit"
            >
              RESET PASSWORD
          </Button>
        </Row>
      </Form>
    </React.Fragment>
  );

  renderSuccessForgotPassword = () => (
    <Row className='no-padding forgot-password-success-row'>
      <h5>Thank you. Your request has been received and you should receive an email from us shortly.</h5>
    </Row>
  );

  render() {
    const { success } = this.props;
    return (
      <Row className="forgot-password-container">
        <Col md={4} />
        <Col className='no-padding'>
          <div className='forgot-password-box'>
            {success
              ? this.renderSuccessForgotPassword()
              : this.renderForgotPasswordForm()}
          </div>
        </Col>
        <Col md={4} />
      </Row>
    );
  }
}

export default ForgotPassword;
