import { connect } from 'react-redux';
import './ResearchManagementPortal.css';
import ResearchManagementPortal from './ResearchManagementPortal';
import {
  getMyAccountRequest,
  profileImageUploadRequest,
  editAccountRequest,
  editContributorProfileRequest,
  editContributorAccountRequest,
  getPublishedReportsRequest,
  getDraftsRequest,
  sendInvitesRequest,
  uploadAvatarImageRequest,
  uploadBannerImageRequest,
} from '../../../redux/actions/account';
import {
  getLineGraphDataRequest,
  getTokenGraphDataRequest,
  sendContributorInvitesRequest,
  getPieChartDataRequest
} from '../../../redux/actions/contributor'
import {
  getContributorStatsRequest,
  unpublishArticleRequest
} from '../../../redux/actions/analytics'
import {
  getAssetProfilesRequest,
} from '../../../redux/actions/researches'
import {
  createTokenFormFromTemplateRequest,
  createAssetProfileRequest,
  submitAssetProfileForVerificationRequest,
  selectAssetProfileRequest,
  resetTokenFormParams,
  editTokenFormRequest,
  uploadTokenFormImageRequest,
  uploadTokenFormDocumentRequest,
  getAssetProfileEditorsRequest,
  searchUsersForAssetProfilesRequest,
  resetSearchUsersAPParams,
  addEditorToAssetProfileRequest,
  getPublishedTemplatesRequest,
  publishAssetProfileRequest,
  unpublishAssetProfileRequest,
} from '../../../redux/actions/tokenform'

const mapStateToProps = state => ({
  accountInfo: state.account.myAccount.info,
  accountImage: state.account.uploadAvatarImage,
  imageLoading: state.account.myAccount.loading,
  publishedReports: state.account.publishedReports,
  drafts: state.account.drafts,
  contributorStats: state.analytics.contributorStats,
  lineGraphData: state.contributors.lineGraphData,
  pieChartData: state.contributors.pieChartData,
  editAccountError: state.account.editAccountError,
  uploadBannerImage: state.account.uploadBannerImage,
  uploadAvatarImage: state.account.uploadAvatarImage,
  assetProfiles: state.research.assetProfiles,
  publishedTemplates: state.token.publishedTemplates,
  tokenForm: state.token.tokenForm,
  tokenFormImage: state.token.tokenFormImage,
  tokenFormDocument: state.token.tokenFormDocument,
  assetProfileEditors: state.token.assetProfileEditors,
  searchEditors: state.token.searchEditors
})

const mapDispatchToProps = {
  getMyAccountRequest,
  profileImageUploadRequest,
  editAccountRequest,
  editContributorProfileRequest,
  editContributorAccountRequest,
  getPublishedReportsRequest,
  getDraftsRequest,
  getContributorStatsRequest,
  unpublishArticleRequest,
  getLineGraphDataRequest,
  getPieChartDataRequest,
  getTokenGraphDataRequest,
  sendContributorInvitesRequest,
  sendInvitesRequest,
  uploadAvatarImageRequest,
  uploadBannerImageRequest,
  createTokenFormFromTemplateRequest,
  getAssetProfilesRequest,
  createAssetProfileRequest,
  submitAssetProfileForVerificationRequest,
  selectAssetProfileRequest,
  resetTokenFormParams,
  editTokenFormRequest,
  uploadTokenFormImageRequest,
  uploadTokenFormDocumentRequest,
  getAssetProfileEditorsRequest,
  searchUsersForAssetProfilesRequest,
  resetSearchUsersAPParams,
  addEditorToAssetProfileRequest,
  getPublishedTemplatesRequest,
  publishAssetProfileRequest,
  unpublishAssetProfileRequest,
};


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResearchManagementPortal)
