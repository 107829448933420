import React, { Component } from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import { history } from '../../configureStore'
import { Link, NavLink } from 'react-router-dom'
import {
    Navbar,
    Nav,
    Container,
    Row,
    Col,
    Image,
    Button,
    Dropdown,
    NavDropdown,
    ListGroup,
    Accordion,
} from 'react-bootstrap'
import jwt from 'jsonwebtoken'
import LogoImg from '../../images/HomePage/FI_LOGO.png'
import {
  AiOutlineTeam,
  AiOutlineSolution,
  AiOutlineWarning,
  AiOutlineReconciliation,
  AiOutlineExperiment,
  AiOutlineFundView
} from 'react-icons/ai'
import {
  FiChevronDown
} from 'react-icons/fi'

class Sidebar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      collapseSidebar: false,
      currentSection: 'user-management'
    }
  }

  renderResearchAccordion = () => {
    return (
      <Dropdown className='admin-list-accordion'>
        <Dropdown.Toggle>
          <Row noGutters className='admin-list-accordion-title'>
            <div>
              <AiOutlineExperiment className='admin-list-item-icon'/>
              Research
            </div>
          </Row>
        </Dropdown.Toggle>

        <Dropdown.Menu className='admin-list-accordion-collapse classify-onboarding'>
          <ul>
            <NavLink to={window.location.pathname} className='admin-list-item-title'>
              <li>Reports</li>
            </NavLink>

            <NavLink to={window.location.pathname} className='admin-list-item-title'>
              <li>Contributor List</li>
            </NavLink>

            <NavLink to={window.location.pathname} className='admin-list-item-title'>
              <li>Featured Contributors</li>
            </NavLink>
          </ul>
        </Dropdown.Menu>
      </Dropdown>
    )
  }

  renderAssetProfilesAccordion = () => {
    const { currentSection } = this.state

    const defaultActiveKey = currentSection == 'users' ? 'user-management' : ''
    return (
      <Accordion className='admin-list-accordion' defaultActiveKey={defaultActiveKey}>
        <Accordion.Toggle eventKey='user-management'>
          <AiOutlineFundView className='admin-list-item-icon'/>
          Asset Profiles
        </Accordion.Toggle>
        <Accordion.Collapse className='admin-list-accordion-collapse' eventKey='user-management'>
          <ul>
            <NavLink to='/admin/create-an-asset-profile' className='admin-list-item-title'>
              <li>Create an Asset Profile</li>
            </NavLink>

            <NavLink to='/admin' className='admin-list-item-title'>
              <li>Asset Profiles List (coming soon)</li>
            </NavLink>
          </ul>
        </Accordion.Collapse>
      </Accordion>
    )
  }

  render() {
    const { collapseSidebar, currentSection } = this.state

    let sidebar
    if (collapseSidebar) {

    } else {
      sidebar = (
        <div className='admin-sidebar'>
          <Col>
            <Row noGutters className='admin-logo-row'>
              <NavLink to='/'>
                <Image
                  className='admin-logo'
                  src={LogoImg} />
              </NavLink>
            </Row>

            <Row noGutters className='admin-system-row'>
              <div className='system-header'>SYSTEM</div>
            </Row>

            <ListGroup variant='flush' className='admin-list-group'>

              <ListGroup.Item
                className='admin-list-item'
                active={currentSection === 'user-management'}>

                <NavLink to='/admin/user-lookup' className='admin-list-item-title'>
                  <Row noGutters>
                    <AiOutlineTeam className='admin-list-item-icon'/>
                    User Management
                  </Row>
                </NavLink>

              </ListGroup.Item>

              <ListGroup.Item
                className='admin-list-item'
                active={currentSection === 'contributor-applications'}>

                <NavLink to={window.location.pathname} className='admin-list-item-title'>
                  <Row noGutters>
                    <AiOutlineSolution className='admin-list-item-icon'/>
                    Contributor Applications
                  </Row>
                </NavLink>

              </ListGroup.Item>

              {this.renderResearchAccordion()}

              <ListGroup.Item
                className='admin-list-item'
                active={currentSection === 'spam-reports'}>

                <NavLink to={window.location.pathname} className='admin-list-item-title'>
                  <Row noGutters>
                    <AiOutlineWarning className='admin-list-item-icon'/>
                    Spam Reports
                  </Row>
                </NavLink>

              </ListGroup.Item>

              <ListGroup.Item
                className='admin-list-item'
                active={currentSection === 'asset-templates'}>

                <NavLink to='/admin/asset-templates' className='admin-list-item-title'>
                  <Row noGutters>
                    <AiOutlineReconciliation className='admin-list-item-icon'/>
                    Asset Templates
                  </Row>
                </NavLink>

              </ListGroup.Item>

              {this.renderAssetProfilesAccordion()}

            </ListGroup>
          </Col>
        </div>
      )
    }

    return sidebar
  }
}

export default Sidebar
