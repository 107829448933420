import React, { PureComponent } from 'react'
import {
  Container,
  Row,
  Col,
  Button,
  Jumbotron,
  Form,
  FormControl,
  InputGroup,
  Tabs,
  Tab,
  Modal,
  Spinner,
  ListGroup,
  Tooltip,
  OverlayTrigger,
  Image,
} from 'react-bootstrap'
import { ResponsiveLine, ResponsiveLineCanvas } from '@nivo/line'
import { ResponsivePie } from '@nivo/pie'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { GoPencil, GoSearch } from "react-icons/go"
import { NavLink } from 'react-router-dom'
import DatePicker from 'react-date-picker'
import Avatar from "react-avatar-edit"
import ImageUploader from 'react-images-upload';
import { toast } from 'react-toastify'
import { AiOutlineQuestionCircle } from 'react-icons/ai'
import { FiShare, FiClipboard, FiMinus, FiPlus } from 'react-icons/fi'
import { MdInfo, MdArrowBack, MdDelete, MdEdit, MdSend, MdClear, MdCheck } from "react-icons/md"
import { IoMdArrowBack } from 'react-icons/io'
import { BrowserRouter as Router, Route, Link } from "react-router-dom"
import { history } from '../../../configureStore'
import defaultAvatarImage from '../../../images/default-avatar-image.png'
import defaultBannerImage from '../../../images/topbar-background-image.png'
import Files from "react-butterfiles"
import { Typeahead } from 'react-bootstrap-typeahead' // ES2015
import countryList from 'react-select-country-list'
import {XYPlot, LineSeries, VerticalGridLines, HorizontalGridLines, XAxis, YAxis} from 'react-vis'

const geographicOptions = [
  "North America",
  "South America",
  "Europe",
  "Middle East",
  "Africa",
  "Asia",
  "Australia/New Zealand",
  "General Focus",
]

const researchOptions = [
  "STO Analysis",
  "Macro Trends",
  "Technology and Protocol Analysis",
  "Trading + TA",
  "Legal + Regulation",
  "Strategy + Advisory",
]

class ResearchManagementPortal extends PureComponent {
  constructor(props) {
    super(props)

    this.locationOptions = countryList().getLabels()

    this.state = {
      showReferAFriendModal: false,
      referAFriendCopyLink: '',
      inviteAContributorLink: '',
      contributorArray: [
        {name: '', email: ''},
      ],
      userArray: [
        {name: '', email: ''},
      ],
      selectedSidebarSection: 'profile-info',
      lineGraphParams: {
        type: '',
        timeFrame: 'getDaily',
        to: '',
        from: '',
        id: '',
      },
      lineGraphData: {
        data: [],
        loaded: false,
      },
      pieChartData: {
        data: [],
        loaded: false,
      },
      showCopyLinkModal: false,
      copyLink: '',
      contributorReportsTab: '',
      fromDate: '',
      toDate: '',
      showButton: false,
      setShow: false,
      show: false,
      firstName: '',
      lastName: '',
      email: '',
      username: '',
      displayName: '',
      about: '',
      industryFocus: '',
      categoryFocus: '',
      geographicFocus: '',
      about: '',
      facebookLink: '',
      twitterLink: '',
      linkedInLink: '',
      showEditForm: false,
      contributorStats: {},
      reportStatsWindow: false,
      selectedReport: {},
      showUnpublishModal: false,
      image: '',
      file: {},
      changeImage: false,
      savedImage: false,
      preview: null,
      src: '',
      stopCloseEdit: false,
      currentlyUnpublishingArticle: false,
      publishedReports: {
        total: 0,
        hits: [],
      },
      selectedTokenMonth: '',
      editUserImage: false,
      editBannerImage: false,
      bannerImage: '',
      avatarImage: '',
      assetProfiles: {
        profiles: {},
        loaded: false,
        loading: false,
        error: ''
      },
      showCreateAssetProfileModal: false,
      publishedTemplates: {
        templates: {},
        loading: false,
        loaded: false,
        error: ''
      },
      tokenForm: {
        form: {},
        loading: false,
        loaded: false,
        error: ''
      },
      tokenFormImage: {
        image: '',
        fieldIndex: '',
        sectionIndex: '',
        loading: false,
        loaded: false,
        error: ''
      },
      tokenFormDocument: {
        file: '',
        fieldIndex: '',
        sectionIndex: '',
        loading: false,
        loaded: false,
        error: ''
      },
      assetProfileEditors: {
        editors: [],
        loading: false,
        loaded: false,
        error: ''
      },
      editorsQuery: '',
      searchEditors: {
        editors: [],
        loading: false,
        loaded: false,
        error: ''
      },
    }
  } // end of constructor(props)

  componentDidMount() {
    const { accountInfo, accountImage } = this.props
    this.props.getPublishedReportsRequest(accountInfo.userId)
    this.props.getDraftsRequest(accountInfo.userId)
    this.props.getContributorStatsRequest()
    this.props.getPieChartDataRequest()
    this.handleSelectSidebarSection('profile-info')
    this.props.getAssetProfilesRequest(accountInfo.userId)
    this.handleResetTokenFormParams()
    this.props.resetSearchUsersAPParams()
    this.props.getPublishedTemplatesRequest()

    this.setState({
      referAFriendCopyLink: accountInfo.userId ? `https://velocitychannel.io/register?source=${accountInfo.userId}` : '',
      inviteAContributorLink: accountInfo.userId ? `https://velocitychannel.io/research/apply?source=${accountInfo.userId}` : '',
    })
  } // end of componentDidMount()

  componentWillReceiveProps(nextProps) {
    if (nextProps !== this.props) {
      const { accountInfo, accountImage, publishedReports } = nextProps
      this.setState({
        image: accountImage.image || accountInfo.image,
        lineGraphData: nextProps.lineGraphData,
        pieChartData: nextProps.pieChartData,
        firstName: accountInfo.first,
        lastName: accountInfo.last,
        email: accountInfo.email,
        username: accountInfo.username,
        displayName: accountInfo.displayName,
        first: accountInfo.first,
        last: accountInfo.last,
        username: accountInfo.username,
        email: accountInfo.email,
        about: accountInfo.about,
        industryFocus: accountInfo.industryFocus,
        categoryFocus: accountInfo.categoryFocus,
        geographicFocus: accountInfo.geographicFocus,
        facebookLink: accountInfo.facebookLink,
        twitterLink: accountInfo.twitterLink,
        linkedInLink: accountInfo.linkedInLink,
        publishedReports: publishedReports,
        avatarImage: accountInfo.image || defaultAvatarImage,
        bannerImage: accountInfo.coverImage || defaultBannerImage,
        assetProfiles: nextProps.assetProfiles,
        publishedTemplates: nextProps.publishedTemplates,
        tokenForm: nextProps.tokenForm,
        tokenFormImage: nextProps.tokenFormImage,
        tokenFormDocument: nextProps.tokenFormDocument,
        assetProfileEditors: nextProps.assetProfileEditors,
        searchEditors: nextProps.searchEditors,
      })

      if (nextProps.tokenFormImage !== this.props.tokenFormImage && nextProps.tokenFormImage.loaded) {
        const { image, fieldIndex, sectionIndex } = nextProps.tokenFormImage
        this.handleChangeImage(image, fieldIndex, sectionIndex)
      }

      if (nextProps.uploadBannerImage !== this.props.uploadBannerImage) {
        this.setState({
          ...this.state,
          editBannerImage: false,
          bannerImage: nextProps.uploadBannerImage.image,
        })
      }

      if (nextProps.uploadAvatarImage !== this.props.uploadAvatarImage) {
        this.setState({
          ...this.state,
          editAvatarImage: false,
          avatarImage: nextProps.uploadAvatarImage.image,
        })
      }

      if (nextProps.contributorStats != this.props.contributorStats) {
        this.setState({contributorStats: nextProps.contributorStats})
      }
      if (accountInfo.about === '-' && accountInfo.isContributor) {
        this.setState({ setShow: true })
      } else {
        this.setState({ setShow: false })
        this.setState({ contributor: accountInfo.contributor })
      }
      if (nextProps.editAccountError === true && this.props.editAccountError === false) {
        this.setState({stopCloseEdit: true})
      } else {
        this.setState({stopCloseEdit: false})
      }
    }
  }

  handleChangeImage = (image, fieldIndex, sectionIndex) => {
    const newSections = this.newSections()
    newSections[sectionIndex].fields[fieldIndex].value = image

    this.handleSetStateAndSave()
  }

  onBeforeFileLoad(e) {
    if(e.target.files[0].size > 1000000){
      alert("File is too big!")
      e.target.value = ""
    }
  }

  onClose = () => {
    this.setState({
      preview: null,
      savedImage: false,
      showButton: false,
      file: {},
    })
  }

  onCrop = preview => {
    this.setState({ showButton: true })
  }

  onFileLoad = file => {
    this.setState({ file: file })
  }

  onSaveImage = () => {
    const { file } = this.state
    if (file.name) {
      this.setState({
        ...this.state,
        savedImage: true,
        showButton: false,
        changeImage: false,
      })
      this.props.profileImageUploadRequest(file)
    } else {
      this.setState({
        ...this.state,
        showButton: false,
        changeImage: false,
      })
    }
  }

  handlePreviewImage = (previewImg) => {
     return previewImg ? this.state.preview : this.state.image
  }

  handleClickChangeImage = () => {
      this.setState({changeImage: !this.state.changeImage})
  } // end of handleClickChangeImage()

  handleOnImageError = e => {
      e.target.src = defaultAvatarImage
  } // end of handleOnImageError()

  handleFormChange = e => {
      const { name, value } = e.target
      this.setState({ [name]:  value })
  } // end of handleFormChange(e)

  handleEditProfileFormChange = e => {
    const { name, value } = e.target
    this.setState({
      ...this.state,
      [name]: value,
    })
  } // end of handleEditProfileFormChange(e)

  handleGetReportEngagementStats = report => {
    const { id } = this.state.lineGraphParams

    if (report.id === id) {
      this.handleDeselectReport()
    } else {
      this.setState({
        ...this.state,
        reportStatsWindow: true,
        selectedReport: report,
        lineGraphParams: {
          ...this.state.lineGraphParams,
          id: report.id
        }
      }, () => this.handleGetContributorStatsAndGetLineGraphData())
    }
  }

  handleCloseModal = () => {
    const { accountInfo } = this.props
    if (!accountInfo.contributor) {
      toast.error("You must fill out your contributor profile before viewing the Research Management Portal")
    } else {
      this.setState({ setShow: false })
    }
  } // end of handleCloseModal()

  handleShowModal = () => {
    this.setState({ setShow: true })
  } // end of handleShowModal()

  onChangeFromDate = e => {
    const { type, timeFrame, id, to } = this.state.lineGraphParams
    if (e === null) {
      this.setState({
        lineGraphParams: {
          ...this.state.lineGraphParams,
          from: '',
        },
      }, () => this.handleGetLineGraphDataRequest())
    } else {
      this.setState({
        lineGraphParams: {
          ...this.state.lineGraphParams,
          from: e.toISOString(),
        },
      }, () => this.handleGetLineGraphDataRequest())
    }
  }

  onChangeToDate = e => {
    const { type, timeFrame, id, from } = this.state.lineGraphParams
    if (e === null) {
      this.setState({
        lineGraphParams: {
          ...this.state.lineGraphParams,
          to: '',
        },
      }, () => this.handleGetLineGraphDataRequest())
    } else {
      this.setState({
        lineGraphParams: {
          ...this.state.lineGraphParams,
          to: e.toISOString(),
        },
      }, () => this.handleGetLineGraphDataRequest())
    }
  }

  formatDate = dateInput => {
    let date = new Date(dateInput)
    const month = date.toLocaleString("en-us", { month: "short" })
    const day = date.getDate()
    return month + " " + day
  } // end of formatDate(dateInput)

  handleShowEditForm = () => {
    const { showEditForm, stopCloseEdit } = this.state
    if (!showEditForm) {
      this.setState({ showEditForm: true })
    } else if (stopCloseEdit === false) {
      this.setState({ showEditForm: false })
    }
  } // end of handleShowEditForm()

  handleSubmit = () => {
    const profile = {
      username: this.state.username,
      displayName: this.state.displayName,
      first: this.state.firstName,
      last: this.state.lastName,
      about: this.state.about,
      industryFocus: this.state.industryFocus,
      categoryFocus: this.state.categoryFocus,
      geographicFocus: this.state.geographicFocus,
      facebookLink: this.state.facebookLink,
      twitterLink: this.state.twitterLink,
      linkedInLink: this.state.linkedInLink,
    }
    this.props.editContributorAccountRequest(profile)
  } // end of handleSubmit()

  handleSaveEditedProfile = () => {
    const profile = {
      username: this.state.username,
      displayName: this.state.displayName,
      first: this.state.firstName,
      last: this.state.lastName,
      about: this.state.about,
      industryFocus: this.state.industryFocus,
      categoryFocus: this.state.categoryFocus,
      geographicFocus: this.state.geographicFocus,
      facebookLink: this.state.facebookLink,
      twitterLink: this.state.twitterLink,
      linkedInLink: this.state.linkedInLink,
    }
    const usernameContainsSpecialCharacters = this.handleUsernameContainsDisallowedCharacters(this.state.username)
    if (usernameContainsSpecialCharacters) {
      toast.info('Your username cannot have special characters. Please remove them and try again.')
    } else {
      this.props.editContributorAccountRequest(profile)
      this.handleShowEditForm()
    }
  } // end of handleSaveEditedProfile()

  handleNavigationTitle = (title, id) => {
    if (title && id) {
      return title.toLowerCase().replace(/%/g, '').replace(/\s+/g, '-').replace(/\?/g,'') + '-' + id
    }
  } // end of handleNavigationTitle(title)

  handleClickUnpublishButton = () => {
    this.setState({showUnpublishModal: true})
  }

  handleConfirmUnpublishButton = () => {
    this.setState({
      currentlyUnpublishingArticle: true,
    })
    this.handleRemoveArticleFromList()
  }

  handleRemoveArticleFromList = () => {
    const { selectedReport, publishedReports } = this.state
    let list = publishedReports.hits
    for (let i = 0; i < list.length; i++) {
      if (list[i]._source.id === selectedReport.id) {
        list.splice(i, 1)
      }
    }
    this.props.unpublishArticleRequest(selectedReport.id)
    setTimeout(() =>
      this.setState({
        showUnpublishModal: false,
        selectedReport: {}
      }), 3000)
  }

  handleSelectSidebarSection = section => {
    this.setState({ selectedSidebarSection: section })
    if (section === 'profile-info') {
    } else if (section === 'followers') {
      this.handleSetParams('follow')
    } else if (section === 'research-engagement') {
      this.handleSetParams('view')
    } else if (section === 'token-management') {
      this.props.getPieChartDataRequest()
    }
  }

  handleResetParams = () => {
    this.setState({
      lineGraphParams: {
        type: '',
        timeFrame: 'getDaily',
        to: '',
        from: '',
        id: '',
      },
      selectedReport: {},
    })
  }

  handleSetParams = type => {
    this.setState({
      lineGraphParams: {
        ...this.state.lineGraphParams,
        type: type,
      },
    }, () => this.handleGetLineGraphDataRequest())
  }

  handleGetTokenGraphDataRequest = () => {
    const params = {
      to: this.state.lineGraphParams.to,
      from: this.state.lineGraphParams.from,
    }
    this.props.getTokenGraphDataRequest(params)
  }

  handleGetLineGraphDataRequest = () => {
    const params = {
      type: this.state.lineGraphParams.type,
      timeFrame: this.state.lineGraphParams.timeFrame,
      to: this.state.lineGraphParams.to,
      from: this.state.lineGraphParams.from,
      id: this.state.lineGraphParams.id,
    }
    this.props.getLineGraphDataRequest(params)
  }

  handleSwitchParamsType = type => {
    this.setState({
      lineGraphParams: {
        ...this.state.lineGraphParams,
        type: type,
      },
    }, () => this.handleGetLineGraphDataRequest())
  }

  handleRenderPublishedReports = publishedReports => {
    if (publishedReports.hits.length > 0) {
      return publishedReports.hits.map(report => this.renderIndividualReport(report._source))
    } else {
      return <div className='missing-reports'>You haven't published any reports yet!</div>
    }
  } // end of handleRenderPublishedReports(publishedReports)

  handleRenderDrafts = drafts => {
    if (drafts.length > 0) {
      return drafts.map(draft => this.renderIndividualDraft(draft))
    } else {
      return <div className='missing-reports'>You don't have any drafts yet!</div>
    }
  } // end of handleRenderDrafts(drafts)

  handleCopyLink = () => {
    document.execCommand("copy")
  }

  handleShowCopyLinkModal = navigationTitle => {
    const { userId } = this.props.accountInfo
    this.setState({showCopyLinkModal: true, copyLink: `https://velocitychannel.io/research/${navigationTitle}?source=${userId}`})
  }

  handleCloseCopyLinkModal = () => {
    this.setState({showCopyLinkModal: false, copyLink: ''})
  }

  handleSelectTimeFrame = timeFrame => {
    this.setState({
      lineGraphParams: {
        ...this.state.lineGraphParams,
        timeFrame: timeFrame,
      },
    }, () => this.handleGetLineGraphDataRequest())
  }

  handleDeselectReport = () => {
    this.setState({
      ...this.state,
      selectedReport: {},
      lineGraphParams: {
        ...this.state.lineGraphParams,
        id: '',
      },
    }, () => this.handleGetContributorStatsAndGetLineGraphData())
  }

  handleGetContributorStatsAndGetLineGraphData = report => {
    const from = this.state.lineGraphParams.from || ''
    const to = this.state.lineGraphParams.to || ''
    const reportId = this.state.selectedReport.id || ''
    this.props.getContributorStatsRequest(from, to, reportId)
    this.handleGetLineGraphDataRequest()
  }

  handleHideReferFriendModal = () => {
    this.setState({
      showReferAFriendModal: false,
    })
    this.handleClearUserArray()
  }

  handleShowReferFriendModal = () => {
    this.setState({
      showReferAFriendModal: true
    })
  }

  handleModifyName = (index, name) => {
    const newArray = this.state.userArray.slice()
    newArray[index].name = name.target.value
    this.setState({
      userArray: newArray
    })
  }

  handleModifyEmail = (index, email) => {
    const newArray = this.state.userArray.slice()
    newArray[index].email = email.target.value
    this.setState({
      userArray: newArray
    })
  }

  handleAddUser = () => {
    let { userArray } = this.state
    const userObject = {
      name: '',
      email: '',
    }
    userArray.push(userObject)
    this.setState({
      userArray: userArray
    }, () => this.forceUpdate())
  }

  handleRemoveUser = index => {
    let { userArray } = this.state
    userArray.splice(index, 1)
    this.setState({
      userArray: userArray
    }, () => this.forceUpdate())
  }

  handleModifyContributorName = (index, name) => {
    const newArray = this.state.contributorArray.slice()
    newArray[index].name = name.target.value
    this.setState({
      contributorArray: newArray
    })
  }

  handleModifyContributorEmail = (index, email) => {
    const newArray = this.state.contributorArray.slice()
    newArray[index].email = email.target.value
    this.setState({
      contributorArray: newArray
    })
  }

  handleAddContributor = () => {
    let { contributorArray } = this.state
    const userObject = {
      name: '',
      email: '',
    }
    contributorArray.push(userObject)
    this.setState({
      contributorArray: contributorArray
    }, () => this.forceUpdate())
  }

  handleRemoveContributor = index => {
    let { contributorArray } = this.state
    contributorArray.splice(index, 1)
    this.setState({
      contributorArray: contributorArray
    }, () => this.forceUpdate())
  }

  handleSendContributorInvites = () => {
    const { contributorArray } = this.state
    this.props.sendContributorInvitesRequest(contributorArray)
    this.handleClearContributorArray()
  }

  handleSendInvites = () => {
    const { userArray } = this.state
    this.props.sendInvitesRequest(userArray)
    this.handleClearUserArray()
  }

  handleClearContributorArray = () => {
    this.setState({
      contributorArray: [
        {name: '', email: ''},
      ],
    })
  }

  handleClearUserArray = () => {
    this.setState({
      userArray: [
        {name: '', email: ''},
      ],
    })
  }

  handleUsernameContainsDisallowedCharacters = username => {
    const disallowedCharacters = "!@#$%^&*(),.?:{}|<>"
    let validation = false
    for (let i = 0; i < username.length; i++) {
      if (disallowedCharacters.indexOf(username.charAt(i)) != -1) {
        validation = true
      }
    }
    return validation
  }

  handleToggleCreateAssetProfileModal = () => {
    this.setState({
      showCreateAssetProfileModal: !this.state.showCreateAssetProfileModal
    })
  }

  handleChangeFormControl = (e, fieldIndex, sectionIndex) => {
    const newSections = this.newSections()
    newSections[sectionIndex].fields[fieldIndex].value = e.target.value

    this.handleSetStateAndSave()
  }

  handleChangeSocialPlatform = (e, fieldIndex, sectionIndex) => {
    const newSections = this.newSections()
    newSections[sectionIndex].fields[fieldIndex].socialPlatform = e.target.value

    this.handleSetStateAndSave()
  }

  handleChangeDisplayText = (e, fieldIndex, sectionIndex) => {
    const newSections = this.newSections()
    newSections[sectionIndex].fields[fieldIndex].displayText = e.target.value

    this.handleSetStateAndSave()
  }

  handleChangeSubfieldValue = (e, fieldIndex, sectionIndex, subfieldIndex) => {
    const newSections = this.newSections()
    newSections[sectionIndex].fields[fieldIndex].fields[subfieldIndex].value = e.target.value

    this.handleSetStateAndSave()
  }

  handleUploadTokenFormImage = (image, fieldIndex, sectionIndex) => {
    this.props.uploadTokenFormImageRequest(image[0], fieldIndex, sectionIndex)
  }

  handleChangeImage = (image, fieldIndex, sectionIndex) => {
    const newSections = this.newSections()
    newSections[sectionIndex].fields[fieldIndex].value = image

    this.handleSetStateAndSave()
  }

  handleClearImage = (fieldIndex, sectionIndex) => {
    const newSections = this.newSections()
    newSections[sectionIndex].fields[fieldIndex].value = ''

    this.handleSetStateAndSave()
  }

  handleUploadTokenFormDocument = (file, fieldIndex, sectionIndex) => {
    const fileData = file[0].src.file
    const fileName = file[0].name
    const field = this.state.tokenForm.form.profile.sections[sectionIndex].fields[fieldIndex]
    field.documentName = fileName

    this.handleSetStateAndSave()

    this.props.uploadTokenFormDocumentRequest(fileData, fieldIndex, sectionIndex)
  }

  handleChangeDocument = (file, fieldIndex, sectionIndex) => {
    const newSections = this.newSections()
    newSections[sectionIndex].fields[fieldIndex].value = file

    this.handleSetStateAndSave()
    toast.info('Document successfully uploaded')
  }

  handleClearDocument = (fieldIndex, sectionIndex) => {
    const field = this.state.tokenForm.form.profile.sections[sectionIndex].fields[fieldIndex]
    field.documentName = ''

    this.handleSetStateAndSave()
  }

  handleChangeLocation = (e, fieldIndex, sectionIndex) => {
    const newSections = this.newSections()
    newSections[sectionIndex].fields[fieldIndex].value = e[0]

    this.handleSetStateAndSave()
  }

  handleChangeDate = (e, fieldIndex, sectionIndex) => {
    const newSections = this.newSections()
    newSections[sectionIndex].fields[fieldIndex].value = e

    this.handleSetStateAndSave()
  }

  handleSetStateAndSave = () => {
    this.setState({
      tokenForm: {
        ...this.state.tokenForm,
      }
    }, () => this.handleEditTokenFormRequest())
  }

  handleEditTokenFormRequest = () => {
    this.props.editTokenFormRequest(this.state.tokenForm.form.profile)
  }

  handleSubmitAssetProfile = profileId => {
    const { accountInfo } = this.props
    this.props.submitAssetProfileForVerificationRequest(profileId, accountInfo.id)
  }

  handleSelectAssetProfile = profileId => {
    this.props.selectAssetProfileRequest(profileId)
    this.props.getAssetProfileEditorsRequest(profileId)
  }

  newSections = () => {
    let newSections = this.state.tokenForm.form.profile.sections
    return newSections
  }

  handleBackToAssetProfiles = () => {
    this.handleResetTokenFormParams()
    // this.props.getAssetProfilesRequest()
  }

  /***************** END OF HANDLERS ******************************************/

  /***************** RENDERS **************************************************/

  renderSidebar = () => {
    const { selectedSidebarSection } = this.state
    return (
      <ListGroup variant='flush' className='rmp-sidebar-listgroup'>
        <ListGroup.Item
          active={selectedSidebarSection === 'profile-info'}
          onClick={() => this.handleSelectSidebarSection('profile-info')}
        >
          Profile Information
        </ListGroup.Item>
        <ListGroup.Item
          active={selectedSidebarSection === 'followers'}
          onClick={() => this.handleSelectSidebarSection('followers')}
        >
          Follower Tracking
        </ListGroup.Item>
        <ListGroup.Item
          active={selectedSidebarSection === 'research-engagement'}
          onClick={() => this.handleSelectSidebarSection('research-engagement')}
        >
          Research Engagement
        </ListGroup.Item>
        <ListGroup.Item
          active={selectedSidebarSection === 'token-management'}
          onClick={() => this.handleSelectSidebarSection('token-management')}
        >
          Token Management
        </ListGroup.Item>
        <ListGroup.Item
          active={selectedSidebarSection === 'invite-a-friend'}
          onClick={() => this.handleSelectSidebarSection('invite-a-friend')}
        >
          Invite a Friend
        </ListGroup.Item>
        <ListGroup.Item
          active={selectedSidebarSection === 'invite-a-contributor'}
          onClick={() => this.handleSelectSidebarSection('invite-a-contributor')}
        >
          Invite a Contributor
        </ListGroup.Item>
        <ListGroup.Item
          active={selectedSidebarSection === 'create-an-asset-profile'}
          onClick={() => this.handleSelectSidebarSection('create-an-asset-profile')}
        >
          Create an Asset Profile
        </ListGroup.Item>
      </ListGroup>
    )
  }

  renderNonContributorSidebar = () => {
    const { selectedSidebarSection } = this.state
    return (
      <ListGroup variant='flush' className='rmp-sidebar-listgroup'>
        <ListGroup.Item
          active={selectedSidebarSection === 'profile-info'}
          onClick={() => this.handleSelectSidebarSection('profile-info')}
        >
          Profile Information
        </ListGroup.Item>
        <ListGroup.Item
          active={selectedSidebarSection === 'invite-a-friend'}
          onClick={() => this.handleSelectSidebarSection('invite-a-friend')}
        >
          Invite a Friend
        </ListGroup.Item>
      </ListGroup>
    )
  }

  renderReferAFriendButton = () => {
    return (
      <Row className='no-padding refer-a-friend-row rmp-refer-a-friend-row'>
        <Button className='rmp-refer-a-friend-button' onClick={this.handleShowReferFriendModal}>Invite A Friend</Button>
      </Row>
    )
  }

  renderContentPane = () => {
    const { selectedSidebarSection, lineGraphParams, tokenForm } = this.state

    if (selectedSidebarSection === 'profile-info') {
      return (
        <React.Fragment>
          <Row className='no-padding rmp-test-row'>
            <Col className='no-padding'>
              <Row className='rmp-banner-row'>
                {this.renderBannerUploader()}
              </Row>
              <Row className='no-padding'>
                <Col className='no-padding engagement-column'>
                  {this.renderImageUploader()}
                </Col>
                <Col md={1} />
                <Col md={6} className='no-padding engagement-column'>
                  <div className='info-card'>
                    {this.renderContributorProfile()}
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </React.Fragment>
      )
    } else if (selectedSidebarSection === 'followers') {
      return (
        <React.Fragment>
          <Col className='no-padding'>
            <Col className='no-padding engagement-column'>
              {this.renderTotalFollowers()}
              {this.renderLineGraphSection()}
            </Col>
          </Col>
        </React.Fragment>
      )
    } else if (selectedSidebarSection === 'research-engagement') {
      return (
        <React.Fragment>
          <Col className='no-padding'>
            <Col className='no-padding engagement-column'>
              <div className='info-card'>
                {this.renderContributorReports()}
              </div>
            </Col>
          </Col>
          <Col md={1} />
          <Col className='no-padding'>
            <Row className='no-padding'>
              {this.renderEngagementStats()}
            </Row>
            {this.renderLineGraphSection()}
            {this.renderGraphButtons()}
          </Col>
        </React.Fragment>
      )
    } else if (selectedSidebarSection === 'token-management') {
      return (
        <React.Fragment>
          <Col className='no-padding'>
            <Col className='no-padding engagement-column'>
              <div className='info-card'>
                {this.renderTokenManagement()}
              </div>
              <div className='info-card-disabled'>
                {this.renderPayoutManagement(0)}
              </div>
            </Col>
          </Col>
          <Col md={1} />
          <Col className='no-padding'>
            {this.renderPieChartSection()}
          </Col>
        </React.Fragment>
      )
    } else if (selectedSidebarSection === 'invite-a-friend') {
      return (
        <React.Fragment>
          <Col md={3} />
          <Col className='no-padding'>
            <Col className='no-padding engagement-column'>
              <div className='info-card'>
                {this.renderInviteAFriend()}
              </div>
            </Col>
          </Col>
          <Col md={3} />
        </React.Fragment>
      )
    } else if (selectedSidebarSection === 'invite-a-contributor') {
      return (
        <React.Fragment>
          <Col md={3} />
          <Col className='no-padding'>
            <Col className='no-padding engagement-column'>
              <div className='info-card'>
                {this.renderInviteSomeoneToPublish()}
              </div>
            </Col>
          </Col>
          <Col md={3} />
        </React.Fragment>
      )
    } else if (selectedSidebarSection === 'create-an-asset-profile') {
      return (
        <React.Fragment>
          <Col className='no-padding'>
            <Col className='no-padding engagement-column'>
              <div className='info-card'>
                {tokenForm.loaded ? this.renderCreateAnAssetProfileForm() : this.renderCreateAnAssetProfile()}
              </div>
            </Col>
          </Col>
        </React.Fragment>
      )
    }
  }

  renderCreateAnAssetProfile = () => {
    const { profiles } = this.state.assetProfiles
    const { tokenForm } = this.state

    let list
    if (this.state.assetProfiles.loaded) {
      if (profiles.total > 0) list = profiles.data[0].map(profile => this.renderIndividualProfile(profile))
      else list = <div className='no-asset-profiles-listed'>No asset profiles listed</div>
    } else if (this.state.assetProfiles.loading) {
      list = this.renderLoading()
    }

    return (
      <Row noGutters className='rmp-create-asset-profile-section'>
        <Col>
          <Row noGutters className='rmp-create-asset-profile-header'>
            <Col>Profile Image</Col>
            <Col>Profile Name</Col>
            <Col>Asset Type</Col>
            <Col>Verification</Col>
            <Col>
              <Button
                onClick={this.handleToggleCreateAssetProfileModal}
                className='create-asset-profile-button'>
                Create
              </Button>
            </Col>
          </Row>
          {list}
        </Col>
      </Row>
    )
  }

  renderCreateAnAssetProfileForm = () => {
    const profileId = this.state.tokenForm.form.profile.id

    return (
      <Row noGutters className='rmp-create-asset-profile-form'>
        <Col>
          <Row noGutters>
            <Col>
              <div className='rmp-return-to-asset-profile' onClick={this.handleBackToAssetProfiles}>
                <IoMdArrowBack /> return to asset profiles
              </div>
            </Col>
            <Col className='right-justified-column'>
              <Button
                onClick={() => this.handleSubmitAssetProfile(profileId)}
                className='rmp-submit-for-verification-button'>
                Submit for Verification <MdSend />
              </Button>
            </Col>
          </Row>
          <Tabs defaultActiveKey='profile-overview'>
            {this.renderProfileOverviewTab()}
            {this.renderEditThisProfileTab()}
          </Tabs>
        </Col>
      </Row>
    )
  }

  renderProfileOverviewTab = () => {
    const { assetProfileEditors, editorsQuery, searchEditors } = this.state
    let list
    if (assetProfileEditors.loaded) list = assetProfileEditors.editors.map(editor => this.renderIndividualEditor(editor))
    else if (assetProfileEditors.loading) list = this.renderLoading()

    let searchList = searchEditors.loaded ? searchEditors.editors.data.map(editor => this.renderIndividualSearchEditors(editor)) : null

    return (
      <Tab className='rmp-asset-profile-overview-tab' eventKey='profile-overview' title='Profile Overview'>
        <h3>Editors</h3>
        <p>Editors are other VelocityChannel users that are able to edit this profile.</p>

        <div className='rmp-editors-container'>
          <Row>
            {list}
          </Row>
        </div>

        <div className='rmp-editors-searchbar-container'>
          <Row noGutters>
            <Col>
              <FormControl
                className='rmp-editors-searchbar'
                placeholder='Search users...'
                onChange={this.handleChangeEditorsQuery}
                value={editorsQuery} />
                {searchList}
            </Col>
          </Row>
        </div>

        <div className='rmp-editors-profile-analytics-container'>
          <h3>Profile Analytics</h3>
        </div>
      </Tab>
    )
  }

  renderIndividualSearchEditors = editor => {
    const { image, username, userId } = editor

    return (
      <div className='rmp-individual-search-editor'>
        {username}
        <Button onClick={() => this.handleAddEditorToAssetProfile(userId)}>Add</Button>
      </div>
    )
  }

  handleAddEditorToAssetProfile = userId => {
    const profileId = this.state.tokenForm.form.profile.id
    this.props.addEditorToAssetProfileRequest(userId, profileId)


  }

  handleChangeEditorsQuery = e => {
    this.setState({ editorsQuery: e.target.value },
      () => this.props.searchUsersForAssetProfilesRequest(this.state.editorsQuery))
  }

  renderIndividualEditor = editor => {
    const { image, username } = editor
    return (
      <div className='rmp-individual-asset-profile-editor'>
        <Image src={image || defaultAvatarImage} />
        {username}
      </div>
    )
  }

  handleUnpublishAssetProfile = profileId => {
    this.props.unpublishAssetProfileRequest(profileId)
  }

  handlePublishAssetProfile = profileId => {
    const { data } = this.state.assetProfiles.profiles
    const { profile } = this.state.tokenForm.form

    this.handleSetStateAndSave()
    this.props.publishAssetProfileRequest(profileId)
  }

  renderEditThisProfileTab = () => {
    const { tokenForm } = this.state
    const templateData = tokenForm.form.template
    const profileData = this.state.tokenForm.form.profile
    const profileId = profileData.id

    // let publishButton
    // if (profileData.isPublished) {
    //   publishButton = (
    //     <Button onClick={() => this.handleUnpublishAssetProfile(profileId)}>
    //       Unpublish
    //     </Button>
    //   )
    // } else {
    //   publishButton = (
    //     <Button onClick={() => this.handlePublishAssetProfile(profileId)}>
    //       Publish
    //     </Button>
    //   )
    // }

    return (
      <Tab className='rmp-edit-this-profile-tab' eventKey='edit-this-profile' title='Edit this Profile'>
        <div className='rmp-edit-this-container'>
          {templateData.sections.map((section, sectionIndex) => this.renderSection(section, sectionIndex))}
          {/* <Row noGutters>
            {publishButton}
          </Row> */}
        </div>
      </Tab>
    )
  }

  renderSection = (sectionData, sectionIndex) => {
    const { name, fields } = sectionData

    return (
      <Row noGutters key={sectionIndex}>
        <Col>
          <Row noGutters className='rmp-ap-form-name-row'>
            <Col>
              {name}
            </Col>
            <Col className='right-justified-column'>
              (* indicates a required field)
            </Col>
          </Row>
          <Row noGutters>
            <Col>
              {fields.map((field, fieldIndex) => this.renderField(field, fieldIndex, sectionIndex))}
            </Col>
          </Row>
        </Col>
      </Row>
    )
  }

  renderField = (templateField, fieldIndex, sectionIndex) => {
    const profileField = this.state.tokenForm.form.profile.sections[sectionIndex].fields[fieldIndex]
    const { fieldType, fieldName, required } = templateField

    const options = templateField.options
    const subfields = templateField.fields

    let showFieldName = required ? fieldName + '*' : fieldName

    let fieldFormControl
    if (fieldType === 'string') {
      fieldFormControl = this.renderStringFormControl(profileField, fieldIndex, sectionIndex)
    } else if (fieldType === 'number') {
      fieldFormControl = this.renderNumberFormControl(profileField, fieldIndex, sectionIndex)
    } else if (fieldType === 'paragraph') {
      fieldFormControl = this.renderParagraphFormControl(profileField, fieldIndex, sectionIndex)
    } else if (fieldType === 'location') {
      fieldFormControl = this.renderLocationFormControl(profileField, fieldIndex, sectionIndex)
    } else if (fieldType === 'image') {
      fieldFormControl = this.renderImageFormControl(profileField, fieldIndex, sectionIndex)
    } else if (fieldType === 'video') {
      fieldFormControl = this.renderVideoFormControl(profileField, fieldIndex, sectionIndex)
    } else if (fieldType === 'document') {
      fieldFormControl = this.renderDocumentFormControl(profileField, fieldIndex, sectionIndex)
    } else if (fieldType === 'dropdown') {
      fieldFormControl = this.renderDropdownFormControl(profileField, fieldIndex, sectionIndex, templateField.options)
    } else if (fieldType === 'date') {
      fieldFormControl = this.renderDateFormControl(profileField, fieldIndex, sectionIndex)
    } else if (fieldType === 'link') {
      fieldFormControl = this.renderLinkFormControl(profileField, fieldIndex, sectionIndex)
    } else if (fieldType === 'social') {
      fieldFormControl = this.renderSocialFormControl(profileField, fieldIndex, sectionIndex)
    } else if (fieldType === 'multi') {
      fieldFormControl = this.renderMultiFormControl(profileField, fieldIndex, sectionIndex, subfields)
    }

    console.log(showFieldName, fieldType)
    return (
      <Form.Group key={sectionIndex + '-' + fieldIndex}>
        <Form.Label>{showFieldName}</Form.Label>
        {fieldFormControl}
      </Form.Group>
    )
  }

  renderStringFormControl = (field, fieldIndex, sectionIndex) => {
    const { fieldType, fieldName, required, value } = field
    const fieldValue = this.state.tokenForm.form.profile.sections[sectionIndex].fields[fieldIndex].value

    return (
      <Form.Control
        className='rmp-ap-form-control'
        placeholder={fieldName + '...'}
        required={required}
        value={fieldValue || ''}
        onChange={e => this.handleChangeFormControl(e, fieldIndex, sectionIndex)}
      />
    )
  }

  renderNumberFormControl = (field, fieldIndex, sectionIndex) => {
    const { fieldType, fieldName, required, value } = field

    function isInputNumber(e) {
      const input = String.fromCharCode(e.which)
      if(!(/[0-9]/.test(input))){
          e.preventDefault()
      }
    }

    return (
      <Form.Control
        className='rmp-ap-form-control'
        placeholder={fieldName + '...'}
        type='number'
        min='1'
        required={required}
        value={value || ''}
        onKeyPress={e => isInputNumber(e)}
        onChange={e => this.handleChangeFormControl(e, fieldIndex, sectionIndex)}
      />
    )
  }

  renderParagraphFormControl = (field, fieldIndex, sectionIndex) => {
    const { fieldType, fieldName, required, value } = field

    return (
      <Form.Control
        className='rmp-ap-form-control'
        placeholder={fieldName + '...'}
        required={required}
        value={value || ''}
        onChange={e => this.handleChangeFormControl(e, fieldIndex, sectionIndex)}
        as='textarea'
      />
    )
  }

  renderLocationFormControl = (field, fieldIndex, sectionIndex) => {
    const { fieldType, fieldName, required, value } = field
    let defaultSelected
    if (value) {
      defaultSelected = [value]
    }

    return (
      <Typeahead
        clearButton
        className='rmp-ap-typeahead'
        defaultSelected={defaultSelected}
        options={this.locationOptions}
        placeholder="Location..."
        onChange={e => this.handleChangeLocation(e, fieldIndex, sectionIndex)}
      />
    )
  }

  renderImageFormControl = (field, fieldIndex, sectionIndex) => {
    const { fieldType, fieldName, required, value } = field
    const { tokenFormImage } = this.state

    let imageUploader = (
      <ImageUploader
        className='rmp-ap-image-uploader'
        singleImage={true}
        withPreview={true}
        buttonText='Choose Image'
        onChange={image => this.handleUploadTokenFormImage(image, fieldIndex, sectionIndex)}
      />
    )

    if (tokenFormImage.loading) {
      imageUploader = (
        <div className='rmp-ap-uploaded-image-container'>
          {this.renderLoading()}
        </div>
      )
    } else if (tokenFormImage.loaded || value) {
      if (value) {
        imageUploader = (
          <div className='rmp-ap-uploaded-image-container'>
            <Image
              src={value}
              className='rmp-ap-uploaded-image'
            />
            <MdClear
              className='rmp-ap-remove-icon'
              onClick={() => this.handleClearImage(fieldIndex, sectionIndex)}
            />
          </div>
        )
      }
    }

    return (
      <Row className='rmp-ap-image-uploader-row'>
        {imageUploader}
      </Row>
    )
  }

  renderVideoFormControl = (field, fieldIndex, sectionIndex) => {
    const { fieldType, fieldName, required, value, displayText } = field

    return (
      <Form.Control
        className='rmp-ap-form-control'
        placeholder='Video URL...'
        required={required}
        value={value || ''}
        onChange={e => this.handleChangeFormControl(e, fieldIndex, sectionIndex)}
      />
    )
  }

  renderDocumentFormControl = (field, fieldIndex, sectionIndex) => {
    const { fieldType, fieldName, required, documentName } = field
    const sections = this.state.tokenForm.form.template.sections
    const fieldValue = sections[sectionIndex].fields[fieldIndex].value
    const tokenFormDocument = this.state.tokenFormDocument

    let documentLoading
    if (tokenFormDocument.loading) {
      documentLoading = this.renderLoading()
    } else if (tokenFormDocument.loaded) {
      documentLoading = <MdCheck />
    }

    let documentNameDisplay
    if (documentName) {
      documentNameDisplay = (
        <React.Fragment>
          {documentName}
          <MdClear
            className='rmp-ap-document-clear-icon'
            onClick={() => this.handleClearDocument(fieldIndex, sectionIndex)}
          />
          {documentLoading}
        </React.Fragment>
      )
    } else {
      documentNameDisplay = 'No document uploaded'
    }

    return (
      <Row className='rmp-ap-document-uploader-row'>
        <Col className='no-padding' sm={3}>
          <Files
            id='test'
              multiple={false}
              maxSize="2mb"
              accept={
                [
                  "application/pdf",
                  'application/msword',
                  'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                ]
              }
              onSuccess={file => this.handleUploadTokenFormDocument(file, fieldIndex, sectionIndex)}
          >
              {({ browseFiles, getDropZoneProps, getLabelProps }) => (
                <>
                    <div {...getDropZoneProps({ className: 'myDropZone' })} />
                    <Button onClick={browseFiles}>
                        Select file...
                    </Button>
                </>
              )}
          </Files>
        </Col>
        <Col className='no-padding'>
          <Row className='rmp-ap-document-name'>
            {documentNameDisplay}
          </Row>
        </Col>
      </Row>
    )
  }

  renderDropdownFormControl = (field, fieldIndex, sectionIndex, options) => {
    const { fieldType, fieldName, required, value } = field

    function renderOption(option) {
      return <option key={option}>{option}</option>
    }

    return (
      <Form.Control
        className='rmp-ap-form-control'
        required={required}
        value={value || ''}
        onChange={e => this.handleChangeFormControl(e, fieldIndex, sectionIndex)}
        as='select'>
        <option>Select an option...</option>
        {options.map(option => renderOption(option))}
      </Form.Control>
    )
  }

  renderDateFormControl = (field, fieldIndex, sectionIndex) => {
    const { fieldType, fieldName, required, value } = field
    const dateValue = value ? new Date(value) : ''

    return (
      <Row className='no-padding'>
        <DatePicker
          className='rmp-ap-datepicker'
          onChange={e => this.handleChangeDate(e, fieldIndex, sectionIndex)}
          value={dateValue}
          calendarType="US"
        />
      </Row>
    )
  }

  renderLinkFormControl = (field, fieldIndex, sectionIndex) => {
    const { fieldType, fieldName, required, value, displayText } = field

    return (
      <Row className='no-padding'>
        <Col className='rmp-aps-link-text'>
          <div className='rmp-aps-form-control-sub-text'>Display Text</div>
          <Form.Control
            className='rmp-ap-form-control'
            placeholder='Display Text...'
            required={required}
            value={displayText || ''}
            onChange={e => this.handleChangeDisplayText(e, fieldIndex, sectionIndex)}
          />
        </Col>
        <Col className='no-padding'>
          <div className='rmp-aps-form-control-sub-text'>Link URL</div>
          <Form.Control
            className='rmp-ap-form-control'
            placeholder='Link URL...'
            required={required}
            value={value || ''}
            onChange={e => this.handleChangeFormControl(e, fieldIndex, sectionIndex)}
          />
        </Col>
      </Row>
    )
  }

  renderSocialFormControl = (field, fieldIndex, sectionIndex) => {
    const { fieldType, fieldName, required, value, socialPlatform } = field

    return (
      <Row className='no-padding'>
        <Col className='rmp-aps-social-dropdown' sm={4}>
          <div className='rmp-aps-form-control-sub-text'>Social Platform</div>
          <Form.Control
            as='select'
            required={required}
            value={socialPlatform || ''}
            onChange={e => this.handleChangeSocialPlatform(e, fieldIndex, sectionIndex)}>
            <option>Website</option>
            <option>Twitter</option>
            <option>LinkedIn</option>
            <option>Facebook</option>
            <option>Telegram</option>
            <option>Medium</option>
          </Form.Control>
        </Col>
        <Col className='no-padding'>
          <div className='rmp-aps-form-control-sub-text'>Link URL</div>
          <Form.Control
            className='rmp-ap-form-control'
            placeholder='Link URL...'
            required={required}
            value={value || ''}
            onChange={e => this.handleChangeFormControl(e, fieldIndex, sectionIndex)}
          />
        </Col>
      </Row>
    )
  }

  renderMultiFormControl = (field, fieldIndex, sectionIndex, subfields) => {
    return subfields.map((subfield, subfieldIndex) => this.renderSubfield(field, subfield, fieldIndex, sectionIndex, subfieldIndex))
  }

  renderSubfield = (field, subfield, fieldIndex, sectionIndex, subfieldIndex) => {
    const { required, fields } = field
    const subfieldValue = fields[subfieldIndex].value

    return (
      <Row className='rmp-ap-subfield'>
        <div className='rmp-aps-form-control-sub-text'>{subfield}</div>
        <Form.Control
          required={required}
          value={subfieldValue || ''}
          onChange={e => this.handleChangeSubfieldValue(e, fieldIndex, sectionIndex, subfieldIndex)}
        />
      </Row>
    )
  }

  renderIndividualProfile = profile => {
    const { name, assetType, verification, image, id } = profile._source

    return (
      <Row noGutters className='rmp-individual-profile'>
        <Col>{image || 'No Image'}</Col>
        <Col>{name || 'Unnamed Asset Profile'}</Col>
        <Col>{assetType}</Col>
        <Col>{verification}</Col>
        <Col>
          <Row className='rmp-individual-profile-icon-row'>
            <MdEdit className='rmp-individual-profile-icon' onClick={() => this.handleSelectAssetProfile(id)} />
            <MdDelete className='rmp-individual-profile-icon-delete' id='delete-icon' />
          </Row>
        </Col>
      </Row>
    )
  }

  renderCreateAssetProfileModal = () => {
    const { showCreateAssetProfileModal } = this.state
    return (
      <Modal
        className='asset-profile-create-new-modal'
        centered
        show={showCreateAssetProfileModal}
        onHide={this.handleToggleCreateAssetProfileModal}>
        {this.renderAssetTypeFormControl()}
      </Modal>
    )
  }

  renderAssetTypeFormControl = () => {
    const { publishedTemplates, assetType } = this.state

    if (publishedTemplates.loaded) {
      const publishedTemplatesData = publishedTemplates.templates.data
      return (
        <Form.Group className='asset-profiles-asset-type' required as={Row}>
          <Form.Label column>Pick an Asset Type</Form.Label>
          <Col className='no-padding' sm={7}>
            <FormControl
              as='select'
              onChange={e => this.handleChangeAssetType(e)}
              className='asset-profiles-asset-type-form-control'>
              <option value='default-asset-type'>Asset Type...</option>
              {/* {publishedTemplatesData.map(template => this.renderIndividualPublishedTemplate(template))} */}
            </FormControl>

            <Row className='right-justified-column no-padding'>
              <Button
                disabled={!assetType}
                onClick={this.handleCreateAssetProfileForm}
                className='asset-profile-create-asset-button'>
                Confirm
              </Button>
            </Row>
          </Col>
        </Form.Group>
      )
    } else {
      return this.renderLoading()
    }
  }

  handleChangeAssetType = e => {
    // this will select the assetType and make the call to retrieve it from the back end
    const assetType = e.target.value
    if (assetType === 'default-asset-type') {
      this.handleResetTokenFormParams()
    } else {
      this.setState({
        assetType: assetType
      })
    }
  }

  handleCreateAssetProfileForm = () => {
    const { accountInfo } = this.props
    this.handleToggleCreateAssetProfileModal()
    this.props.createAssetProfileRequest(this.state.assetType, accountInfo.userId)
  }

  handleResetTokenFormParams = () => {
    this.setState({
      assetType: '',
      tokenForm: {
        form: {},
        loading: false,
        loaded: false,
        error: ''
      },
    }, () => this.props.resetTokenFormParams())
  }


  renderIndividualPublishedTemplate = template => {
    const { id, assetType } = template
    return (
      <option value={assetType} key={id}>{assetType}</option>
    )
  }

  renderTokenManagement = () => {
    const pieChartData = this.state.pieChartData
    let renderContent

    function renderLoading() {
      return (
        <Row className='category no-padding spinner-row'>
          <Spinner className='loading-spinner' animation="border" role="status" variant='primary' />
        </Row>
      )
    }

    return (
      <Col className='no-padding'>
        <h4>Token Management</h4>
        <Row className='no-padding'>
          <Col className='no-padding title rmp-token-data-month bolded-text'>
            Month
          </Col>
          <Col className='no-padding contributor-profile-right-side bolded-text'>
            Tokens
          </Col>
        </Row>
        {pieChartData.loaded ? this.renderTokenContent(pieChartData) : renderLoading()}
      </Col>
    )
  }

  renderPayoutManagement = () => {
    return (
      <React.Fragment>
        <Col>
          <Row>
            <h4>Payout Management</h4>
          </Row>
          <Tabs className='rmp-payout-tabs' defaultActiveKey='myBalance'>
            <Tab eventKey='myBalance' title='My Balance'>
              {this.renderComingSoon()}
            </Tab>
            <Tab eventKey='Redeem Tokens' title='Redeem Tokens'>
              {this.renderComingSoon()}
            </Tab>
            <Tab eventKey='Transfer' title='Transfer'>
              {this.renderComingSoon()}
            </Tab>
            <Tab eventKey='Token Data' title='Token Data'>
              {this.renderComingSoon()}
            </Tab>
          </Tabs>
        </Col>
      </React.Fragment>
    )
  } // end of renderPayoutManagement()

  renderComingSoon = () => (
  <Row className='coming-soon'>
    <div className='coming-soon-bubble'>
      Coming Soon
    </div>
  </Row>
) // end of renderComingSoon()

  renderTokenContent = data => {
    let tokenData = []
    let reversedArray = []
    if (data.loaded) {
      tokenData = data.data
      return tokenData.map(token => this.renderMonthlyTokenData(token))
    }
  }

  renderMonthlyTokenData = token => {
    const { month, total, totalReleased } = token
    const { selectedTokenMonth } = this.state
    let tempTokenMonth = selectedTokenMonth === '' ? month : selectedTokenMonth
    const rowIdSelector = selectedTokenMonth === month ? 'active-token-month' : ''
    return (
      <OverlayTrigger
        placement='right'
        overlay={
          <Tooltip className='article-collections-tooltip'>
            In {month} our network distributed a total of {totalReleased} tokens - this is your allocation based on your activity and engagement with your content.
          </Tooltip>
        }
      >
      <Row
        className='category no-padding rmp-token-month'
        key={month}
        onClick={() => this.setState({selectedTokenMonth: month})}
        id={rowIdSelector}
        >
        <Col className='no-padding title rmp-token-data-month'>
          {month}
        </Col>
        <Col className='no-padding contributor-profile-right-side'>
          {total}
        </Col>
      </Row>
    </OverlayTrigger>
    )
  }

  renderGraphButtons = () => {
    const { type } = this.state.lineGraphParams
    return (
      <div className='graph-buttons info-card'>
        <Form>
          <Form.Check
            className='radio-button'
            inline
            label='Views'
            type='radio'
            checked={type === 'view'}
            onChange={() => this.handleSwitchParamsType('view')}
          />
          <Form.Check
            inline
            label='Upvotes'
            type='radio'
            checked={type === 'upvote'}
            onChange={() => this.handleSwitchParamsType('upvote')}
          />
          <Form.Check
            inline
            label='Downvotes'
            type='radio'
            checked={type === 'downvote'}
            onChange={() => this.handleSwitchParamsType('downvote')}
          />
          <Form.Check
            inline
            label='Bookmarks'
            type='radio'
            checked={type === 'bookmark'}
            onChange={() => this.handleSwitchParamsType('bookmark')}
          />
          <Form.Check
            inline
            label='Comments'
            type='radio'
            checked={type === 'comment'}
            onChange={() => this.handleSwitchParamsType('comment')}
          />
        </Form>
      </div>
    )
  }

  renderTestGraph = () => {
    const data = new Array(19).fill(0).reduce((prev, curr) => [...prev, {
      x: prev.slice(-1)[0].x + 1,
      y: prev.slice(-1)[0].y * (0.9 + Math.random() * 0.2)
    }], [{x: 0, y: 10}])
    return (
      <XYPlot height={300} width={300} color='red'>
        <LineSeries data={data} />
      </XYPlot>
    )
  }

  renderLineGraphSection = () => {
    const { selectedSidebarSection, lineGraphParams, selectedReport } = this.state
    const type = lineGraphParams.type
    const lineGraphData = this.state.lineGraphData.data
    const gained = this.state.lineGraphData.loaded ? lineGraphData[0].gained : ''
    const today = new Date()
    const minDate = new Date()
    minDate.setMonth(minDate.getMonth() - 6)
    let startDate
    let endDate
    let differenceText
    let convertedFromDate
    let convertedToDate

    if (this.state.lineGraphData.loaded) {
      startDate = lineGraphData[0].start ? new Date(lineGraphData[0].start) : ''
      endDate = lineGraphData[0].end ? new Date(lineGraphData[0].end) : ''
      convertedFromDate = lineGraphParams.from ? new Date(lineGraphParams.from) : startDate
      convertedToDate = lineGraphParams.to ? new Date(lineGraphParams.to) : endDate
      if (Math.sign(gained) === 1) {
        differenceText = `gained ${gained}`
      } else if (Math.sign(gained) === -1) {
        differenceText = `lost ${gained}`
      } else {
        differenceText = `gained ${gained}`
      }
    }

    let typeContext = ''
    let reportContext
    if (type === 'follow') {
      typeContext = (gained === 1) ? type + 'er' : type + 'ers'
      reportContext = ''
    } else {
      typeContext = (gained === 1) ? type : type + 's'
      reportContext = selectedReport.title ? `on ${selectedReport.title}` : 'across all reports'
    }

    let followers = 0
    if (this.props.contributorStats.loaded) {
      followers = 'followers' in this.props.contributorStats.stats.data ? this.props.contributorStats.stats.data.followers : 0
    }

    const headerText = this.state.lineGraphData.loaded ? `You ${differenceText} ${typeContext} ${reportContext}` : headerText

    function activeIdForButtons(params, timeFrame) {
      if (params.timeFrame === timeFrame) {
        return 'active'
      }
    }

    const buttonsRow = (
      <Row className='no-padding rmp-line-graph-button-row'>
        <Button
          className='time-frame-button'
          onClick={() => this.handleSelectTimeFrame('getDaily')}
          id={activeIdForButtons(lineGraphParams, 'getDaily')}
        >
          Day
        </Button>
        <Button
          className='time-frame-button'
          onClick={() => this.handleSelectTimeFrame('getWeekly')}
          id={activeIdForButtons(lineGraphParams, 'getWeekly')}
        >
          Week
        </Button>
        <Button
          className='time-frame-button'
          onClick={() => this.handleSelectTimeFrame('getMonthly')}
          id={activeIdForButtons(lineGraphParams, 'getMonthly')}
        >
          Month
        </Button>
      </Row>
    )

    return (
      <div className='info-card'>
          <Row className='date-picker-row'>
            <Col className='no-padding'>
              <p className='rmp-date-picker-text'>From</p>
              <DatePicker
                className='lol'
                calendarClassName='date-picker-calendar'
                className="date-picker-button"
                onChange={e => this.onChangeFromDate(e)}
                value={convertedFromDate}
                calendarType='US'
                maxDate={today}
                minDate={minDate}
              />
            </Col>
            <Col className='no-padding right-justified-column test-column'>
              <p className='rmp-date-picker-text'>To</p>
              <DatePicker
                calendarClassName='date-picker-calendar'
                className="date-picker-button"
                onChange={e => this.onChangeToDate(e)}
                value={convertedToDate}
                calendarType='US'
                maxDate={today}
                minDate={minDate}
              />
            </Col>
          </Row>
          <Row className='no-padding'>
            <p className='line-graph-header-text'>{headerText}</p>
          </Row>
          {this.renderLineGraph(lineGraphData)}
        {selectedSidebarSection === 'engagement' ? this.renderGraphButtons() : null}
      </div>
    )
  }

  renderLineGraph = data => {
    return (
      <div className='rmp-line-graph'>
        <ResponsiveLine
          className='responsive-line-graph'
          data={data}
          margin={{ top: 20, right: 20, bottom: 20, left: 50 }}
          axisTop={null}
          axisRight={null}
          axisBottom={null}
          enablePoints={false}
          enableArea={true}
          enableGridX={false}
          enableGridY={false}
          axisLeft={{
            orient: 'left',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'count',
            legendOffset: -40,
            legendPosition: 'middle',
          }}
          colors={{ scheme: 'category10' }}
          useMesh
          tooltip={(point) => this.renderLineGraphTooltip(point)}
        />
      </div>
    )
  }

  renderLineGraphTooltip = data => {
    const { color } = data.point
    const { x, y } = data.point.data
    const { type } = this.state.lineGraphParams
    const key = (y === 1) ? type : type + 's'

    return (
      <div className='rmp-graph-tooltip' style={{color: color}}>
        <Row className='no-padding'>
          <Col className='no-padding'>
            {x}
          </Col>
          <Col className='no-padding right-justified-column'>
            {y} {key}
          </Col>
        </Row>
      </div>
    )
  }

  renderPieChartSection = () => {
    const { pieChartData, selectedTokenMonth } = this.state
    let tempTokenMonth

    if (selectedTokenMonth === '') {
      this.setState({selectedTokenMonth: pieChartData.data[0].month})
      tempTokenMonth = pieChartData.data[0].month
    } else {
      tempTokenMonth = selectedTokenMonth
    }

    let filteredData = pieChartData.data.filter(datum => {
      return datum.month === tempTokenMonth
    })

    let engagementData = filteredData[0].byEngagement.filter(datum => datum.value !== 0) // filters out data with a value of 0

    if (filteredData[0].total > 0) {
      return (
        <div className='info-card'>
          <Row className='no-padding line-graph-header-text temp-token-month'>
            <Col className='no-padding'>
              {tempTokenMonth}
            </Col>
            <Col className='no-padding right-justified-column'>
              <OverlayTrigger
                placement='auto'
                overlay={
                  <Tooltip className='article-collections-tooltip'>
                    The engagement types that contributored to your token allocation during this month.
                  </Tooltip>
                }
              >
                <AiOutlineQuestionCircle className='rmp-pie-chart-info-icon'/>
              </OverlayTrigger>
            </Col>
          </Row>
          <div className='rmp-line-graph'>
            <ResponsivePie
              className='responsive-line-graph'
              data={engagementData}
              enableArea={true}
              enableRadialLabels={false}
              axisLeft={{
                orient: 'left',
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: 'count',
                legendOffset: -40,
                legendPosition: 'middle',
              }}
              colors={{ scheme: 'category10' }}
              slicesLabelsTextColor="#ffffff"
              // sliceLabel={'id'}
              sliceLabel={d => this.renderPieChartLabel(d)}
              tooltip={(e) => this.renderPieChartTooltip(e)}
            />
          </div>
        </div>
      )
    } else {
      return (
        <div className='info-card'>
          <Row className='no-padding line-graph-header-text temp-token-month'>
            {tempTokenMonth}
          </Row>
          <div className='rmp-line-graph rmp-no-tokens-earned'>
            You haven't earned any tokens this month.
          </div>
        </div>
      )
    }
  }

  renderPieChartLabel = data => {
    return data.id.replace('From ', '')
  }

  renderPieChartTooltip = data => {
    const { color, id, label, value } = data
    const roundedValue = Math.round(value)
    return (
      <div className='rmp-pie-chart-tooltip no-padding' style={{color: color}}>
        <Row className='no-padding'>
          <Col className='no-padding' md={7}>
            {id}
          </Col>
          <Col className='no-padding right-justified-column'>
            {roundedValue}%
          </Col>
        </Row>
      </div>
    )
  }

  renderContributorProfile = () => {
    const { showEditForm } = this.state
    const {
      displayName,
      username,
      firstName,
      lastName,
      email,
      about,
      industryFocus,
      categoryFocus,
      geographicFocus,
      facebookLink,
      twitterLink,
      linkedInLink,
    } = this.state

    const contributorParams = (
      <React.Fragment>
        <Row className='category no-padding'>
          <Col className='title no-padding'>
            Display Name
          </Col>
          <Col className='no-padding contributor-profile-right-side'>
            {displayName}
          </Col>
        </Row>
        <Row className='category no-padding'>
          <Col className='title no-padding'>
            About Me
          </Col>
          <Col className='no-padding contributor-profile-right-side'>
            {about}
          </Col>
        </Row>
        <Row className='category no-padding'>
          <Col className='title no-padding'>
            Industry Focus
          </Col>
          <Col className='no-padding contributor-profile-right-side'>
            {industryFocus}
          </Col>
        </Row>
        <Row className='category no-padding'>
          <Col className='title no-padding'>
            Category Focus
          </Col>
          <Col className='no-padding contributor-profile-right-side'>
            {categoryFocus}
          </Col>
        </Row>
        <Row className='category no-padding'>
          <Col className='title no-padding'>
            Geographic Focus
          </Col>
          <Col className='no-padding contributor-profile-right-side'>
            {geographicFocus}
          </Col>
        </Row>
        <Row className='category no-padding'>
          <Col className='title no-padding'>
            Facebook
          </Col>
          <Col className='no-padding contributor-profile-right-side'>
            {facebookLink}
          </Col>
        </Row>
        <Row className='category no-padding'>
          <Col className='title no-padding'>
            Twitter
          </Col>
          <Col className='no-padding contributor-profile-right-side'>
            {twitterLink}
          </Col>
        </Row>
        <Row className='category no-padding'>
          <Col className='title no-padding'>
            LinkedIn
          </Col>
          <Col className='no-padding contributor-profile-right-side'>
            {linkedInLink}
          </Col>
        </Row>
      </React.Fragment>
    )

    if (showEditForm) {
      return this.renderContributorProfileEdit()
    } else {
      return (
        <React.Fragment>
          <Col>
            <Row>
              <Col className='no-padding'>
                <h4>User Profile</h4>
              </Col>
              <Col className='pencil-div'>
                <GoPencil
                  className="edit-account-button"
                  onClick={this.handleShowEditForm}
                />
              </Col>
            </Row>
            <Row className='category no-padding'>
              <Col className='title no-padding'>
                Username
              </Col>
              <Col className='no-padding contributor-profile-right-side'>
                {username}
              </Col>
            </Row>
            <Row className='category no-padding'>
              <Col className='title no-padding'>
                Name
              </Col>
              <Col className='no-padding contributor-profile-right-side'>
                {firstName + ' ' + lastName}
              </Col>
            </Row>
            <Row className='category no-padding'>
              <Col className='title no-padding'>
                Email
              </Col>
              <Col className='no-padding contributor-profile-right-side'>
                {email}
              </Col>
            </Row>
            {this.props.accountInfo.isContributor ? contributorParams : null}
          </Col>
        </React.Fragment>
      )
    }
  } // end of renderContributorProfile()

  renderContributorProfileEdit = () => {
    const {
      displayName,
      username,
      email,
      firstName,
      lastName,
      about,
      industryFocus,
      categoryFocus,
      geographicFocus,
      facebookLink,
      twitterLink,
      linkedInLink,
    } = this.state

    const contributorParams = (
      <React.Fragment>
        <Row className='category no-padding'>
          <Col className='title no-padding'>
            Display Name
          </Col>
          <Col className='right-justified-column'>
            <FormControl
              placeholder='Display Name...'
              name='displayName'
              value={displayName}
              onChange={this.handleEditProfileFormChange}
              className='contributor-profile-edit-form-control'
            />
          </Col>
        </Row>
        <Row className='category no-padding'>
          <Col className='title no-padding'>
            About Me
          </Col>
          <Col className='right-justified-column'>
            <FormControl
              placeholder='About me...'
              name='about'
              as="textarea"
              value={about}
              onChange={this.handleEditProfileFormChange}
              className='contributor-profile-edit-form-control'
            />
          </Col>
        </Row>
        <Row className='category no-padding'>
          <Col className='title no-padding'>
            Industry Focus
          </Col>
          <Col className='right-justified-column'>
            <Form.Control
              className='research-search-dropdown'
              name='industryFocus'
              as='select'
              onChange={this.handleFormChange}
              value={industryFocus}
              size='sm'
              >
                <option value="">Industry</option>
                <option>Art</option>
                <option>Banking</option>
                <option>Blockchain</option>
                <option>Cryptocurrency</option>
                <option>Custody</option>
                <option>Energy</option>
                <option>Entertainment</option>
                <option>Financial Tech</option>
                <option>Gaming</option>
                <option>Healthcare</option>
                <option>Investment Fund</option>
                <option>Legal</option>
                <option>Manufacturing</option>
                <option>Real Estate</option>
                <option>Retail</option>
                <option>Software/Technology</option>
                <option>Sports</option>
                <option>Supply Chain</option>
                <option>Travel</option>
              </Form.Control>
            </Col>
          </Row>
          <Row className='category no-padding'>
            <Col className='title no-padding'>
              Category Focus
            </Col>
            <Col className='right-justified-column'>
              <Form.Control
                className='research-search-dropdown'
                name='categoryFocus'
                as='select'
                onChange={this.handleFormChange}
                value={categoryFocus}
                size='sm'
                >
                  <option value=''>Category</option>
                  <option>STO Analysis</option>
                  <option>Macro Trends</option>
                  <option>Technology and Protocol Analysis</option>
                  <option>Trading + TA</option>
                  <option>Legal + Regulation</option>
                  <option>Strategy + Advisory</option>
                </Form.Control>
              </Col>
            </Row>
            <Row className='category no-padding'>
              <Col className='title no-padding'>
                Geographic Focus
              </Col>
              <Col className='right-justified-column'>
                <Form.Control
                  className='research-search-dropdown'
                  name='geographicFocus'
                  as='select'
                  onChange={this.handleFormChange}
                  value={geographicFocus}
                  size='sm'
                >
                  <option value=''>Region...</option>
                  <option>North America</option>
                  <option>South America</option>
                  <option>Europe</option>
                  <option>Africa</option>
                  <option>Asia</option>
                  <option>Australia/New Zealand</option>
                </Form.Control>
              </Col>
            </Row>
            <Row className='category no-padding'>
              <Col className='title no-padding'>
                Facebook
              </Col>
              <Col className='right-justified-column'>
                <FormControl
                  placeholder='Facebook...'
                  name='facebookLink'
                  value={facebookLink}
                  onChange={this.handleEditProfileFormChange}
                  className='contributor-profile-edit-form-control'
                />
              </Col>
            </Row>
            <Row className='category no-padding'>
              <Col className='title no-padding'>
                Twitter
              </Col>
              <Col className='right-justified-column'>
                <FormControl
                  placeholder='Twitter...'
                  name='twitterLink'
                  value={twitterLink}
                  onChange={this.handleEditProfileFormChange}
                  className='contributor-profile-edit-form-control'
                />
              </Col>
            </Row>
            <Row className='category no-padding'>
              <Col className='title no-padding'>
                LinkedIn
              </Col>
              <Col className='right-justified-column'>
                <FormControl
                  placeholder='LinkedIn...'
                  name='linkedInLink'
                  value={linkedInLink}
                  onChange={this.handleEditProfileFormChange}
                  className='contributor-profile-edit-form-control'
                />
              </Col>
            </Row>
      </React.Fragment>
    )

    return (
      <React.Fragment>
        <Col>
          <Row>
            <Col className='no-padding'>
              <h4>User Profile</h4>
            </Col>
            <Col className='pencil-div'>
              <Row>
                <Button onClick={this.handleSaveEditedProfile}>Save</Button>
                <GoPencil
                  className="edit-account-button"
                  onClick={this.handleShowEditForm}
                />
              </Row>
            </Col>
          </Row>
          <Form>
            <Row className='category no-padding'>
              <Col className='title no-padding'>
                Username
              </Col>
              <Col className='right-justified-column'>
                <FormControl
                  placeholder='Username...'
                  name='username'
                  value={username}
                  onChange={this.handleEditProfileFormChange}
                  className='contributor-profile-edit-form-control'
                />
              </Col>
            </Row>
            <Row className='category no-padding'>
              <Col className='title no-padding'>
                Name
              </Col>
              <Col className='right-justified-column'>
                <Row className='no-padding'>
                  <FormControl
                    placeholder='First Name...'
                    name='firstName'
                    value={firstName}
                    onChange={this.handleEditProfileFormChange}
                    className='contributor-profile-edit-form-control'
                  />
                  <FormControl
                    placeholder='Last Name...'
                    name='lastName'
                    value={lastName}
                    onChange={this.handleEditProfileFormChange}
                    className='contributor-profile-edit-form-control'
                  />
                </Row>
              </Col>
            </Row>
            <Row className='category no-padding'>
              <Col className='title no-padding'>
                Email
              </Col>
              <Col className='right-justified-column'>
                <FormControl
                  placeholder='Email...'
                  name='email'
                  value={email}
                  onChange={this.handleEditProfileFormChange}
                  className='contributor-profile-edit-form-control'
                />
              </Col>
            </Row>
            {this.props.accountInfo.isContributor ? contributorParams : null}
          </Form>
        </Col>
      </React.Fragment>
    )
  } // end of renderContributorProfileEdit(profile)

  renderUnpublishButton = () => {
    if (this.state.lineGraphParams.id) {
      return (
        <Row className='selected-report-unpublish-row'>
          <Button onClick={this.handleClickUnpublishButton}>Unpublish</Button>
        </Row>
      )
    }
  }

  renderTotalFollowers = () => {
    const follows = this.state.contributorStats.loaded ? this.state.contributorStats.stats.data.follows: 0
    return (
      <Col md={2} className='no-padding info-card rmp-engagement-stats'>
        <Row className='no-padding engagement-text'>
          Total Followers
        </Row>
        <Row className='no-padding engagement-stat'>
          {follows}
        </Row>
      </Col>
    )
  }

  renderEngagementStats = () => {
    const comments = this.state.contributorStats.loaded ? this.state.contributorStats.stats.data.comments: ''
    const upvotes = this.state.contributorStats.loaded ? this.state.contributorStats.stats.data.upvotes: ''
    const downvotes = this.state.contributorStats.loaded ? this.state.contributorStats.stats.data.downvotes: ''
    const views = this.state.contributorStats.loaded ? this.state.contributorStats.stats.data.views: ''
    const convertedFromDate = this.state.fromDate ? new Date(this.state.fromDate) : ''
    const convertedToDate = this.state.toDate ? new Date(this.state.toDate) : ''
    const totalEngagementStatsId = !this.state.lineGraphParams.id ? 'hidden' : null

    return (
      <React.Fragment>
        <Col className='no-padding info-card rmp-engagement-stats'>
          <Row className='no-padding engagement-text'>
            Total Views
          </Row>
          <Row className='no-padding engagement-stat'>
            {views}
          </Row>
          {/* Total Views: {views} */}
        </Col>
        <Col className='no-padding info-card rmp-engagement-stats'>
          <Row className='no-padding engagement-text'>
            Total Upvotes
          </Row>
          <Row className='no-padding engagement-stat'>
            {upvotes}
          </Row>
        </Col>
        <Col className='no-padding info-card rmp-engagement-stats'>
          <Row className='no-padding engagement-text'>
            Total Downvotes
          </Row>
          <Row className='no-padding engagement-stat'>
            {downvotes}
          </Row>
        </Col>
        <Col className='no-padding info-card rmp-engagement-stats'>
          <Row className='no-padding engagement-text'>
            Total Comments
          </Row>
          <Row className='no-padding engagement-stat'>
            {comments}
          </Row>
        </Col>
      </React.Fragment>
    )
  } // end of renderEngagementStats()

  renderContributorReports = () => {
    const { drafts } = this.props
    const publishedReports = this.state.publishedReports
    const selectedId = this.state.lineGraphParams.id
    const rowIdSelector = !selectedId ? 'active' : ''
    let allReportsButton = (
      <Row className='rendered-individual-report-top-row no-padding rmp-all-reports-button-row' id={rowIdSelector}>
        <Col className='no-padding rendered-individual-report-col' md={9} xs={9}>
          <Row className='report-title-info-button'>
            All Reports
          </Row>
        </Col>
        <Col className='no-padding rendered-individual-report-col'>
          <Row className='report-info-button-row'>
            <MdInfo className='report-info-button' onClick={this.handleDeselectReport} />
          </Row>
        </Col>
        {/* <Col md={1} xs={1} /> */}
      </Row>
    )

    if (publishedReports.hits.length === 0) {
      allReportsButton = ''
    }

    return (
      <React.Fragment>
        <Col>
          <Row className='no-padding contributor-reports-search-bar-row'>
            <InputGroup>
              <FormControl
                className="contributor-search-bar"
                // onChange={e => this.setState({ searchKey: e.target.value })}
                // onKeyPress={e => this.onKeyPress(e)}
              />
              <InputGroup.Append>
                <Button
                  className="contributor-search-button"
                  // onClick={() => {
                  //     this.onSearchButtonClick()
                  // }}
                >
                  <GoSearch />
                </Button>
              </InputGroup.Append>
            </InputGroup>
          </Row>
          <Tabs defaultActiveKey='publishedReports' className='contributor-report-tabs'>
            <Tab className='published-tab' eventKey='publishedReports' title='Published Reports'>
              <Row className='no-padding tabs-row'>
                <Col className='no-padding'>
                  {allReportsButton}
                  {this.handleRenderPublishedReports(publishedReports)}
                </Col>
              </Row>
            </Tab>
            <Tab className='drafts-tab' eventKey='drafts' title='Drafts'>
              <Row className='no-padding tabs-row'>
                <Col className='no-padding'>
                  {this.renderDraftsLink()}
                  {this.handleRenderDrafts(drafts)}
                </Col>
              </Row>
            </Tab>
          </Tabs>
        </Col>
      </React.Fragment>
    )
  } // end of renderContributorReports()

  renderDraftsLink = () => (
    <Row className='drafts-link-row'>
      <Link to='/research/drafts' className='drafts-manager-link'>
        Click here to visit the Research Drafts Manager
      </Link>
    </Row>
  ) // end of renderDraftsLink()

  renderIndividualReport = data => {
    const {
      title,
      summary,
      tags,
      category,
      industry,
      date,
      brief,
      datePublished,
      published,
      id,
    } = data
    let fixedTitle = title ? title : 'Untitled Report'
    let navigationTitle = title ? this.handleNavigationTitle(title, id) : ''
    const selectedId = this.state.lineGraphParams.id
    const rowIdSelector = selectedId === id ? 'active' : ''
    let unpublishButton

    if (rowIdSelector === 'active') {
      unpublishButton = (
        <Button className='unpublish-button' onClick={this.handleClickUnpublishButton}>
          Unpublish
        </Button>
      )
    }

    function returnPublishedDate(dateInput, published) {
      if (published) {
        let date = new Date(dateInput)
        const month = date.toLocaleString("en-us", { month: "short" })
        const day = date.getDate()
        return (
          <Row className='report-date'>
            Published on {month + " " + day}
          </Row>
        )
      }
    } // end of returnPublishedDate(date)

    return (
      <Row className='rendered-individual-report-top-row no-padding' key={id} id={rowIdSelector}>
        <Col className='no-padding rendered-individual-report-col' md={8} xs={8}>
          {returnPublishedDate(datePublished, published)}
          <Row className='report-title-info-button'>
            <NavLink to={`/research/${navigationTitle}`}>{fixedTitle}</NavLink>
          </Row>
        </Col>
        <Col className='no-padding rendered-individual-report-col'>
          <Row className='report-info-button-row'>
            {unpublishButton}
            <FiShare className='report-info-button' onClick={() => this.handleShowCopyLinkModal(navigationTitle)} />
            <MdInfo className='report-info-button' onClick={() => this.handleGetReportEngagementStats(data)} />
          </Row>
        </Col>
      </Row>
    )
  } // end of renderIndividualReport(data)

  renderIndividualDraft = data => {
    const {
      title,
      summary,
      tags,
      category,
      industry,
      date,
      brief,
      datePublished,
      published,
      id,
    } = data
    let fixedTitle = title ? title : 'Untitled Report'

    return (
      <Row className='no-padding rendered-individual-report-top-row' key={id}>
        <Col className='no-padding rendered-individual-report-col' md={9} xs={9}>
          <Row className='no-padding report-title-info-button'>
            <NavLink to={`/research/drafts`}>{fixedTitle}</NavLink>
          </Row>
        </Col>
        <Col className='no-padding rendered-individual-report-col right-justified-column' md={3} xs={3}>
          {/* <MdInfo className='report-info-button' /> */}
        </Col>
      </Row>
    )
  }

  renderCopyLinkModal = () => {
    const { showCopyLinkModal, copyLink } = this.state
    return (
      <Modal className='copy-link-modal' show={showCopyLinkModal} onHide={this.handleCloseCopyLinkModal}>
        <Modal.Body closeButton>
          <Row className='no-padding'>
            Use this unique link to share this report and earn Velocity Ledger tokens for every new reader that views it.
          </Row>
          <Row className='no-padding copy-link-control-row'>
            <Col md={11} className='no-padding'>
              <FormControl className='copy-link-form-control' value={copyLink} />
            </Col>
            <Col md={1} className='no-padding clipboard-col'>
              <CopyToClipboard text={copyLink} onCopy={() => toast.info('Copied link to clipboard')}>
                <FiClipboard className='report-info-button' />
              </CopyToClipboard>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    )
  }

  renderProfileFormModal = () => {
    // if the accountInfo does not have a contributor value,
    // render the profile form modal
    const {
      show,
      setShow,
      displayName,
      about,
      industryFocus,
    } = this.state
    return (
      <Modal className='profile-form-modal' show={setShow} onHide={this.handleCloseModal}>
        <Modal.Header>
          <Modal.Title>Please fill out your contributor profile</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form as={Row}>
            <Form.Group as={Col} sm={5} md={5} className='image-uploader'>
              {/* <Form.Label>Account Image</Form.Label> */}
              {this.renderImageUploader()}
            </Form.Group>
            <Col />
            <Form.Group as={Col} sm={5} md={5} className='profile-form-content'>
              <Row className='no-padding'>
                <Form.Label>Display Name</Form.Label>
                <div className='small-text'>(This is the name that will be shown when you publish content.)</div>
              </Row>
              <FormControl
                required
                name='displayName'
                value={displayName}
                onChange={this.handleFormChange}
                className="contributor-application-form-control"
              />
              <Row className='no-padding'>
                <Form.Label>About</Form.Label>
                <div className='small-text'>(A description about yourself for your profile.)</div>
              </Row>
              <FormControl
                required
                name='about'
                value={about}
                onChange={this.handleFormChange}
                className="contributor-application-form-control"
              />
              <Form.Label>
                Industry Focus
              </Form.Label>
              <Form.Control
                name='industryFocus'
                as="select"
                value={industryFocus}
                className="contributor-application-form-control"
                onChange={this.handleFormChange}>
                  <option value="">Industry...</option>
                  <option>Art</option>
                  <option>Banking</option>
                  <option>Blockchain</option>
                  <option>Cryptocurrency</option>
                  <option>Custody</option>
                  <option>Energy</option>
                  <option>Entertainment</option>
                  <option>Financial Tech</option>
                  <option>Gaming</option>
                  <option>Healthcare</option>
                  <option>Investment Fund</option>
                  <option>Legal</option>
                  <option>Manufacturing</option>
                  <option>Real Estate</option>
                  <option>Retail</option>
                  <option>Software/Technology</option>
                  <option>Sports</option>
                  <option>Supply Chain</option>
                  <option>Travel</option>
              </Form.Control>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.handleSubmit}>Submit</Button>
        </Modal.Footer>
      </Modal>
    )
  } // end of renderProfileFormModal()

  handleUploadAvatarImageRequest = image => {
    this.props.uploadAvatarImageRequest(image[0])
  }

  handleTurnOffEditAvatarImage = () => {
    this.setState({
      editAvatarImage: false,
    })
  }

  renderImageUploader = () => {
    const { changeImage, editAvatarImage, avatarImage } = this.state

    if (editAvatarImage) {
        return (
            <React.Fragment>
                <Row className='image-uploader-row' id='account-image'>
                  <ImageUploader
                    className='rmp-image-uploader'
                    withIcon={false}
                    buttonText='Choose Avatar'
                    label=''
                    singleImage
                    onChange={this.handleUploadAvatarImageRequest}
                  />
                </Row>
                <Row className='save-image-button-row'>
                    <Button className="save-image" onClick={this.handleTurnOffEditAvatarImage}>
                        Cancel
                    </Button>
                </Row>
            </React.Fragment>
        )
    } else {
        return (
            <React.Fragment>
                <Row className='image-uploader-row' id='account-image'>
                    <div className='image-uploader-container'>
                        <Image
                            className="rmp-uploaded-image"
                            src={avatarImage || defaultAvatarImage}
                            alt="Preview"
                            onError={this.handleOnImageError}
                        />
                        <div
                            className="image-overlay"
                            onClick={this.handleEditAvatarImage}
                        >
                            <div className='image-overlay-text'>
                                Change Image?
                            </div>
                        </div>
                    </div>
                </Row>
            </React.Fragment>
        )
    }
  } // end of renderImageUploader()

  handleUploadBannerImageRequest = image => {
    this.props.uploadBannerImageRequest(image[0])
  }

  renderBannerUploader = () => {
    const { editBannerImage, bannerImage } = this.state

    if (!editBannerImage) {
      return (
        <React.Fragment>
          <Image className='rmp-banner-image' src={bannerImage} />
          <GoPencil className='rmp-banner-edit-icon' onClick={this.handleToggleEditBannerImage}/>
        </React.Fragment>
      )
    } else {
      return (
        <React.Fragment>
          <ImageUploader
            className='rmp-banner-uploader'
            withIcon={false}
            buttonText='Choose Banner'
            label=''
            singleImage
            onChange={this.handleUploadBannerImageRequest}
          />
          <GoPencil className='rmp-banner-edit-icon' onClick={this.handleToggleEditBannerImage}/>
        </React.Fragment>
      )
    }
  }

  handleToggleEditBannerImage = () => {
    this.setState({
      editBannerImage: !this.state.editBannerImage
    })
  }

  handleEditAvatarImage = () => {
    this.setState({
      editAvatarImage: true
    })
  }

  renderUnpublishModal = () => {
    const { currentlyUnpublishingArticle } = this.state
    let modalContent
    let backdrop = true // determines if the modal can be clicked out of

    if (currentlyUnpublishingArticle) {
      backdrop = 'static'
      modalContent = (
        <Modal.Body>
          <Row className='unpublish-modal-text-row'>
            Please wait while we unpublish your article...
          </Row>
          <Row className='category no-padding spinner-row'>
            <Spinner className='loading-spinner' animation="border" role="status" variant='primary' />
          </Row>
        </Modal.Body>
      )
    } else {
      backdrop = true
      modalContent = (
        <Modal.Body>
          <Row className='unpublish-modal-text-row'>
            Are you sure you want to unpublish this article?
          </Row>
          <Row className='unpublish-modal-text-row'>
            It will be moved to your drafts.
          </Row>
          <Row className='unpublish-modal-button-row'>
            <Button className='unpublish-modal-unpublish-button' onClick={() => this.handleConfirmUnpublishButton()}>Unpublish</Button>
            <Button className='unpublish-modal-cancel-button' onClick={() => this.setState({showUnpublishModal: false})}>Cancel</Button>
          </Row>
        </Modal.Body>
      )
    }

    return (
      <Modal
        className='unpublish-modal'
        backdrop={backdrop}
        show={this.state.showUnpublishModal}
        closeButton
        centered
        keyboard
        onHide={() => this.setState({showUnpublishModal: false})}
        >
          {modalContent}
      </Modal>
    )
  }

  renderReferAFriendFormControl = index => {
    const { userArray } = this.state
    const name = userArray[index].name
    const email = userArray[index].email
    return (
      <Row className='no-padding' key={index}>
        <Col className='no-padding'>
          <Row className='no-padding refer-a-friend-control-row'>
            <Col className='no-padding' md={3}>
              <FormControl className='refer-a-friend-form-control' placeholder='Name...' value={name} onChange={e => this.handleModifyName(index, e)} />
            </Col>
            <Col md={1} />
            <Col className='no-padding' md={7}>
              <FormControl className='refer-a-friend-form-control' placeholder='Email Address...' value={email} onChange={e => this.handleModifyEmail(index, e)}  />
            </Col>
            <Col md={1} className='no-padding clipboard-col'>
              <FiMinus className='report-info-button refer-a-friend-remove-button' onClick={() => this.handleRemoveUser(index)}/>
            </Col>
          </Row>
        </Col>
      </Row>
    )
  }

  renderInviteSomeoneToPublishFormControl = index => {
    const { contributorArray } = this.state
    const name = contributorArray[index].name
    const email = contributorArray[index].email
    return (
      <Row className='no-padding' key={index}>
        <Col className='no-padding'>
          <Row className='no-padding refer-a-friend-control-row'>
            <Col className='no-padding' md={3}>
              <FormControl className='refer-a-friend-form-control' placeholder='Name...' value={name} onChange={e => this.handleModifyContributorName(index, e)} />
            </Col>
            <Col md={1} />
            <Col className='no-padding' md={7}>
              <FormControl className='refer-a-friend-form-control' placeholder='Email Address...' value={email} onChange={e => this.handleModifyContributorEmail(index, e)}  />
            </Col>
            <Col md={1} className='no-padding clipboard-col'>
              <FiMinus className='report-info-button refer-a-friend-remove-button' onClick={() => this.handleRemoveContributor(index)}/>
            </Col>
          </Row>
        </Col>
      </Row>
    )
  }

  renderReferAFriendModal = () => {
    const { showReferAFriendModal, referAFriendCopyLink, userArray } = this.state
    return (
      <Modal className='refer-a-friend-modal' show={showReferAFriendModal} onHide={this.handleHideReferFriendModal}>
        <Modal.Body>
          <Row className='no-padding'>
            Use this unique link to refer a friend to Velocity Channel.
          </Row>
          <Row className='no-padding copy-link-control-row'>
            <Col md={11} className='no-padding'>
              <FormControl className='copy-link-form-control' value={referAFriendCopyLink} />
            </Col>
            <Col md={1} className='no-padding clipboard-col'>
              <CopyToClipboard text={referAFriendCopyLink} onCopy={() => toast.info('Copied link to clipboard')}>
                <FiClipboard className='report-info-button' />
              </CopyToClipboard>
            </Col>
          </Row>
          <Row className='no-padding copy-link-control-row'>
            <p className='refer-a-friend-text-p'>Know any writers or researchers that may be interested in publishing for Velocity Channel? Send them an invite to get them started and earn VL Tokens for every contributor that joins:</p>
            <Col className='no-padding'>
              {userArray.map((user, index) => this.renderReferAFriendFormControl(index))}
              <Row className='no-padding refer-a-friend-modal-bottom-row'>
                <Col md={11} />
                <Col className='no-padding clipboard-col'>
                  <FiPlus className='report-info-button' onClick={this.handleAddUser}/>
                </Col>
              </Row>
              <Row className='no-padding center-justified-row'>
                <Button className='rmp-refer-a-friend-button' onClick={this.handleSendInvites}>
                  Send Invites
                </Button>
              </Row>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    )
  }

  renderLoading = () => (
    <Spinner className='asset-profile-loading-spinner' animation="border" role="status" variant='primary' />
  )

  renderInviteSomeoneToPublish = () => {
    const { showReferAFriendModal, inviteAContributorLink, contributorArray } = this.state
    return (
      <React.Fragment>
        <Row className='no-padding rmp-invite-header-row'>
          Invite Someone to Publish
        </Row>
        <Row className='no-padding copy-link-control-row'>
          <p className='refer-a-friend-text-p'>Know any writers or researchers that may be interested in publishing for Velocity Channel? Send them an invite to get them started and earn VL Tokens for every contributor that joins:</p>
          <Col className='no-padding'>
            {contributorArray.map((user, index) => this.renderInviteSomeoneToPublishFormControl(index))}
            <Row className='no-padding refer-a-friend-modal-bottom-row'>
              <Col md={11} />
              <Col className='no-padding clipboard-col'>
                <FiPlus className='report-info-button' onClick={this.handleAddContributor}/>
              </Col>
            </Row>
            <Row className='no-padding center-justified-row'>
              <Button className='rmp-refer-a-friend-button' onClick={this.handleSendContributorInvites}>
                Send Invites
              </Button>
            </Row>
          </Col>
        </Row>
        <Row className='no-padding rmp-refer-a-friend-link-text'>
          Or, just share this unique link with them directly:
        </Row>
        <Row className='no-padding copy-link-control-row'>
          <Col md={11} className='no-padding'>
            <FormControl className='copy-link-form-control' value={inviteAContributorLink} />
          </Col>
          <Col md={1} className='no-padding clipboard-col'>
            <CopyToClipboard text={inviteAContributorLink} onCopy={() => toast.info('Copied link to clipboard')}>
              <FiClipboard className='report-info-button' />
            </CopyToClipboard>
          </Col>
        </Row>
      </React.Fragment>
    )
  }

  renderInviteAFriend = () => {
    const { showReferAFriendModal, referAFriendCopyLink, userArray } = this.state
    return (
      <React.Fragment>
        <Row className='no-padding rmp-invite-header-row'>
          Invite a Friend
        </Row>
        <Row className='no-padding copy-link-control-row'>
          <p className='refer-a-friend-text-p'>Know anyone that would be interested in our content? Send them an invite to get them started and earn tokens when they sign up:</p>
          <Col className='no-padding'>
            {userArray.map((user, index) => this.renderReferAFriendFormControl(index))}
            <Row className='no-padding refer-a-friend-modal-bottom-row'>
              <Col md={11} />
              <Col className='no-padding clipboard-col'>
                <FiPlus className='report-info-button' onClick={this.handleAddUser}/>
              </Col>
            </Row>
            <Row className='no-padding center-justified-row'>
              <Button className='rmp-refer-a-friend-button' onClick={this.handleSendInvites}>
                Send Invites
              </Button>
            </Row>
          </Col>
        </Row>
        <Row className='no-padding rmp-refer-a-friend-link-text'>
          Or, just share this unique link with them directly:
        </Row>
        <Row className='no-padding copy-link-control-row'>
          <Col md={11} className='no-padding'>
            <FormControl className='copy-link-form-control' value={referAFriendCopyLink} />
          </Col>
          <Col md={1} className='no-padding clipboard-col'>
            <CopyToClipboard text={referAFriendCopyLink} onCopy={() => toast.info('Copied link to clipboard')}>
              <FiClipboard className='report-info-button' />
            </CopyToClipboard>
          </Col>
        </Row>
      </React.Fragment>
    )
  }

  render() {
    let headerText = ''
    const { selectedSidebarSection } = this.state
    const { accountInfo } = this.props
    if (selectedSidebarSection === 'profile-info') {
      headerText = 'PROFILE INFORMATION'
    } else if (selectedSidebarSection === 'followers') {
      headerText = 'FOLLOWER TRACKING'
    } else if (selectedSidebarSection === 'research-engagement') {
      headerText = 'RESEARCH ENGAGEMENT'
    } else if (selectedSidebarSection === 'token-management') {
      headerText = 'TOKEN MANAGEMENT'
    } else if (selectedSidebarSection === 'invite-a-friend') {
      headerText = 'INVITE A FRIEND'
    } else if (selectedSidebarSection === 'invite-a-contributor') {
      headerText = 'INVITE A CONTRIBUTOR'
    } else if (selectedSidebarSection === 'create-an-asset-profile') {
      headerText = 'CREATE AN ASSET PROFILE'
    }

    return (
      <Container className='research-management-portal'>
        <div className='profile-form-modal'>
          {this.renderReferAFriendModal()}
          {this.renderProfileFormModal()}
          {this.renderUnpublishModal()}
          {this.renderCopyLinkModal()}
          {this.renderCreateAssetProfileModal()}
        </div>
        <Container className='research-management-portal-container'>
          <Tab.Container defaultActiveKey='#profile-info'>
            <Row className='no-padding rmp-container-row'>
              <Col md={2} className='no-padding rmp-sidebar-column'>
                {accountInfo.isContributor ? this.renderSidebar() : this.renderNonContributorSidebar()}
              </Col>
              <Col md={1} />
              <Col className='no-padding'>
                <Row className='header-row'>
                  <h3>{headerText}</h3>
                </Row>
                <Row className='content-row'>
                  {this.renderContentPane()}
                </Row>
              </Col>
              <Col md={1} />
            </Row>
          </Tab.Container>
        </Container>
      </Container>
    )
  } // end of render()
} // end of ResearchManagementPortal class

export default ResearchManagementPortal
