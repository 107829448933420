import { connect } from 'react-redux';
import Login from './Login';
import { loginRequest, loginFromModalRequest } from '../../redux/actions/login';
import { resetForgotPassword } from '../../redux/actions/forgotpassword';
const mapStateToProps = state => ({});

const mapDispatchToProps = {
  loginRequest,
  resetForgotPassword,
  loginFromModalRequest,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Login);
