import { put, takeLatest, call } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { FORGOT_PASSWORD_REQUEST } from '../../constants/forgotpassword';
import {
  forgotPasswordError,
  forgotPasswordSuccess,
} from '../../actions/forgotpassword';
import request from '../../../utils/apiRequest';

function* forgotPassword(action) {
  try {
    const requestData = {
      email: action.email,
    };
    const responseData = yield call(
      request,
      'account/forgot',
      'POST',
      requestData,
    );
    toast.success('Will send the reset password link!');
    yield put(forgotPasswordSuccess(responseData));
  } catch (err) {
    // toast.error('Reset password error!');
    yield put(forgotPasswordError());
  }
}

export default function* forgotPasswordSaga() {
  yield takeLatest(FORGOT_PASSWORD_REQUEST, forgotPassword);
}
