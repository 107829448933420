import React, { PureComponent } from 'react'
import { history } from '../../../configureStore'
import {
  Row,
  Col,
  Container,
  Spinner,
  Image,
  FormControl,
  Table,
  NavLink,
} from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Select from 'react-select'
import ReactPaginate from 'react-paginate'
import { MdSearch } from 'react-icons/md'
import { initGA, logPageView } from "../../../utils/analytics"
import MetaTags from 'react-meta-tags'
import countryList from 'react-select-country-list'
import { Typeahead } from 'react-bootstrap-typeahead' // ES2015
import LogoImg from '../../../images/logoImage.js' // for metatags


class SearchAssets extends PureComponent {
  constructor(props) {
    super(props)

    this.locationOptions = countryList().getLabels()

    this.state = {
      listOfAssetsForSearch: this.props.listOfAssetsForSearch,
      listOfAssetTypes: this.props.listOfAssetTypes,
      listOfFiltersForAssetType: this.props.listOfFiltersForAssetType,
      selectedAssetType: '',
      assetTypeSearchQuery: '',
      profileFilterValues: {},
    }
  }

  componentDidMount() {
    this.props.resetListOfFiltersForAssetType()
    this.props.getAssetsWithParamsRequest()
    this.props.getListOfAssetTypesRequest()
  }

  componentWillReceiveProps(nextProps) {
    if (this.props !== nextProps) {
      this.setState({
        listOfFiltersForAssetType: nextProps.listOfFiltersForAssetType,
        listOfAssetsForSearch: nextProps.listOfAssetsForSearch,
        listOfAssetTypes: nextProps.listOfAssetTypes,
      })
    }
  }

  /************* HANDLERS *****************************************************/
  handleChangeProfileFilter = e => {
    const { selectedAssetType, profileFilterValues } = this.state
    const { name, value } = e.target

    const newProfileFilterValues = profileFilterValues
    newProfileFilterValues[name] = value
    newProfileFilterValues.assetType = selectedAssetType

    this.props.getAssetsWithParamsRequest(newProfileFilterValues)
  }

  handleChangeProfileFilterLocation = (value, name) => {
    const { selectedAssetType, profileFilterValues } = this.state

    const newProfileFilterValues = profileFilterValues
    newProfileFilterValues[name] = value
    newProfileFilterValues.assetType = selectedAssetType

    this.props.getAssetsWithParamsRequest(newProfileFilterValues)
  }

  handleRouteChange = assetName => {
    const path = `/assets/${assetName}`
    history.push(path)
  }

  handleSearchAssetTypes = e => {
    const params = { template_id: e.target.value }
    this.props.getAssetsWithParamsRequest(params)
    this.props.getListOfFiltersForAssetTypeRequest(params)
  }

  handleChangeAssetTypeSearchQuery = e => {
    const { selectedAssetType, profileFilterValues } = this.state
    const query = e.target.value
    const assetType = selectedAssetType

    const newProfileFilterValues = profileFilterValues
    newProfileFilterValues.assetType = selectedAssetType
    newProfileFilterValues.q = query

    let newState = this.state
    newState.assetTypeSearchQuery = query

    this.props.getListOfFiltersForAssetTypeRequest(newProfileFilterValues)
  }

  /************* END OF HANDLERS **********************************************/


  /************* RENDERS ******************************************************/

  renderLoading = () => (
    <Row noGutters className='loading-bar-row'>
      <Spinner animation='border' variant='light' />
    </Row>
  )

  renderSearchAssets = () => {
    const {
      assetTypeSearchQuery,
      listOfAssetTypes,
      listOfAssetsForSearch,
      profileFilterValues,
      listOfFiltersForAssetType,
    } = this.state

    let loadingInfo, tableContent
    if (listOfAssetsForSearch.loaded) {
      if (listOfAssetsForSearch.assets.total == 0) {
        loadingInfo = (
          <tr>
            <Row noGutters className='table-loading-row'>
              No assets found
            </Row>
          </tr>
        )
      } else {
        tableContent = listOfAssetsForSearch.assets.docs.map((asset, index) => this.renderAssetData(asset, index))
      }
    } else if (listOfAssetsForSearch.loading) {
      loadingInfo = (
        <tr>
          <Row noGutters className='table-loading-row'>
            <Spinner className='asset-profile-loading-spinner' animation='border' />
          </Row>
        </tr>
      )
    }

    let options
    if (listOfAssetTypes.loaded) {
      options = listOfAssetTypes.assetTypes.docs.map(assetType => this.renderAssetTypeOption(assetType))
    } else if (listOfAssetTypes.loading) {
      options = this.renderLoading()
    }

    return (
      <Col className='search-assets-content'>
        <Row noGutters className='search-assets-query-row'>
          <MdSearch />
          <FormControl
            name='q'
            className='search-assets-form-control'
            placeholder='Search...'
            onChange={this.handleChangeProfileFilter}
            value={profileFilterValues.q} />
        </Row>

        <Row noGutters className='search-assets-form-row'>
          <FormControl
            custom
            as='select'
            className='search-assets-select'
            onChange={this.handleSearchAssetTypes}>
            <option value=''>Select an Asset Type...</option>
            {options}
          </FormControl>
        </Row>

        <Row noGutters className='filters-for-asset-types-row'>
          {this.renderFiltersForAssetType()}
        </Row>
        <Table responsive bordered className='admin-table search-assets-table'>
          <thead>
            <tr>
              {this.renderHeaderCells()}
            </tr>
          </thead>
          <tbody>
            {tableContent}
            {loadingInfo}
          </tbody>
        </Table>
      </Col>
    )
  }

  renderHeaderCells = () => {
    let cells
    if (this.state.listOfFiltersForAssetType.loaded) {
      const preview = this.state.listOfFiltersForAssetType.preview
      const nameCell = preview.includes('name')
      ? (
        <th className='search-assets-table-cell'>
          <div className='search-assets-table-cell-div'>
            Name
          </div>
        </th>
      ) : ''
      const descriptionCell = preview.includes('description')
      ? (
        <th className='search-assets-table-cell'>
          <div className='search-assets-table-cell-div'>
            Description
          </div>
        </th>
      ) : ''

      const assetTypeCell = preview.includes('assetType')
      ? (
        <th className='search-assets-table-cell'>
          <div className='search-assets-table-cell-div'>
            Asset Type
          </div>
        </th>
      ) : ''

      const industryCell = preview.includes('industry')
      ? (
        <th className='search-assets-table-cell'>
          <div className='search-assets-table-cell-div'>
            Industry
          </div>
        </th>
      ) : ''

      const locationCell = preview.includes('location')
      ? (
        <th className='search-assets-table-cell'>
          <div className='search-assets-table-cell-div'>
            Location
          </div>
        </th>
      ) : ''

      const marketDataCells = preview.includes('marketData')
      ? (
        <React.Fragment>
          <th className='search-assets-table-cell'>
            <div className='search-assets-table-cell-div'>
              Price
            </div>
          </th>
          <th className='search-assets-table-cell'>
            <div className='search-assets-table-cell-div'>
              1D High
            </div>
          </th>
          <th className='search-assets-table-cell'>
            <div className='search-assets-table-cell-div'>
              1D Low
            </div>
          </th>
        </React.Fragment>
      ) : ''

      cells = (
        <React.Fragment>
          {nameCell}
          {descriptionCell}
          {assetTypeCell}
          {industryCell}
          {locationCell}
          {marketDataCells}
        </React.Fragment>
      )

    } else {
      cells = (
        <React.Fragment>
          <th className='search-assets-table-cell'>
            <div className='search-assets-table-cell-div'>
              Name
            </div>
          </th>
          <th className='search-assets-table-cell'>
            <div className='search-assets-table-cell-div'>
              Description
            </div>
          </th>
          <th className='search-assets-table-cell'>
            <div className='search-assets-table-cell-div'>
              Asset Type
            </div>
          </th>
          <th className='search-assets-table-cell'>
            <div className='search-assets-table-cell-div'>
              Industry
            </div>
          </th>
          <th className='search-assets-table-cell'>
            <div className='search-assets-table-cell-div'>
              Location
            </div>
          </th>
          <th className='search-assets-table-cell'>
            <div className='search-assets-table-cell-div'>
              Price
            </div>
          </th>
          <th className='search-assets-table-cell'>
            <div className='search-assets-table-cell-div'>
              1D High
            </div>
          </th>
          <th className='search-assets-table-cell'>
            <div className='search-assets-table-cell-div'>
              1D Low
            </div>
          </th>
        </React.Fragment>
      )
    }

    return cells
  }

  renderAssetData = (asset, index) => {
    const number = index + 1

    return (
      <tr className='asset-data-row' key={asset.name}
        onClick={() => this.handleRouteChange(asset.name)}>
        {this.renderBodyCells(asset)}
      </tr>
    )
  }

  renderBodyCells = asset => {
    const { name, assetType, description, industry, location, _id, image, template_id, prices } = asset
    const price = prices ? `$ ${prices.price}` : ''
    const high = prices ? `$ ${prices.high}` : ''
    const low = prices ? `$ ${prices.low}` : ''

    let desc
    if (description) {
      if (description.length > 105) {
        desc = description.substr(0, 105) + '...'
      } else {
        desc = description
      }
    }

    let templateName
    if (template_id) {
      templateName = template_id.name
    } else {
      templateName = ''
    }

    let cells
    if (this.state.listOfFiltersForAssetType.loaded) {
      const preview = this.state.listOfFiltersForAssetType.preview
      const nameCell = preview.includes('name')
      ? (
        <th className='search-assets-table-cell'>
          <div className='search-assets-table-cell-div'>
            {name}
          </div>
        </th>
      ) : ''
      const descriptionCell = preview.includes('description')
      ? (
        <th className='search-assets-table-cell'>
          <div className='search-assets-table-cell-div'>
            {description}
          </div>
        </th>
      ) : ''

      const assetTypeCell = preview.includes('assetType')
      ? (
        <th className='search-assets-table-cell'>
          <div className='search-assets-table-cell-div'>
            {assetType}
          </div>
        </th>
      ) : ''

      const industryCell = preview.includes('industry')
      ? (
        <th className='search-assets-table-cell'>
          <div className='search-assets-table-cell-div'>
            {industry}
          </div>
        </th>
      ) : ''

      const locationCell = preview.includes('location')
      ? (
        <th className='search-assets-table-cell'>
          <div className='search-assets-table-cell-div'>
            {location}
          </div>
        </th>
      ) : ''

      const marketDataCells = preview.includes('marketData')
      ? (
        <React.Fragment>
          <th className='search-assets-table-cell'>
            <div className='search-assets-table-cell-div'>
              {price}
            </div>
          </th>
          <th className='search-assets-table-cell'>
            <div className='search-assets-table-cell-div'>
              {high}
            </div>
          </th>
          <th className='search-assets-table-cell'>
            <div className='search-assets-table-cell-div'>
              {low}
            </div>
          </th>
        </React.Fragment>
      ) : ''

      cells = (
        <React.Fragment>
          {nameCell}
          {descriptionCell}
          {assetTypeCell}
          {industryCell}
          {locationCell}
          {marketDataCells}
        </React.Fragment>
      )

    } else {
      cells = (
        <React.Fragment>
          <th className='search-assets-table-cell'>
            <Row noGutters className='search-assets-table-row'>
              <Image src={image} className='asset-type-table-image' />
              {name}
            </Row>
          </th>
          <th className='search-assets-table-cell description'>
            {desc}
          </th>
          <th className='search-assets-table-cell'>
            <div className='search-assets-table-cell-div'>
              {templateName}
            </div>
          </th>
          <th className='search-assets-table-cell'>
            <div className='search-assets-table-cell-div'>
              {industry}
            </div>
          </th>
          <th className='search-assets-table-cell'>
            <div className='search-assets-table-cell-div'>
              {location}
            </div>
          </th>
          <th className='search-assets-table-cell'>
            <div className='search-assets-table-cell-div'>
              {price}
            </div>
          </th>
          <th className='search-assets-table-cell'>
            <div className='search-assets-table-cell-div'>
              {high}
            </div>
          </th>
          <th className='search-assets-table-cell'>
            <div className='search-assets-table-cell-div'>
              {low}
            </div>
          </th>
        </React.Fragment>
      )
    }

    return cells
  }

  renderAssetTypeOption = option => {
    const { assetType, _id } = option
    return <option key={_id} value={_id}>{assetType}</option>
  }

  renderFiltersForAssetType = () => {
    const { loaded, filters, loading } = this.state.listOfFiltersForAssetType

    let renderedFilters
    if (loaded && filters.length > 0) renderedFilters = filters.map(filter => this.renderIndividualFilter(filter))
    else if (loading) renderedFilters = this.renderLoading()

    return renderedFilters
  }

  renderIndividualFilter = filter => {
    const { fieldName, options, fieldType } = filter

    let content
    let renderedOptions
    if (fieldType == 'dropdown') {
      renderedOptions = options.map(option => this.renderIndividualFilterOption(option))
      content = (
        <div className='filter-container'>
          {fieldName}:
          <FormControl
            name={fieldName}
            as='select'
            key={fieldName}
            onChange={this.handleChangeProfileFilter}
            className='rendered-asset-filter'>
            <option value=''>Select an option...</option>
            {renderedOptions}
          </FormControl>
        </div>

      )
    } else if (fieldType == 'location') {
      content = (
        <div className='filter-container'>
          {fieldName}:
          <Typeahead
            className='rendered-asset-filter location-filter'
            onChange={e => this.handleChangeProfileFilterLocation(e, fieldName)}
            options={this.locationOptions} />
        </div>
      )
    }

    return content
  }

  renderIndividualFilterOption = option => (
    <option value={option} key={option}>{option}</option>
  )

  renderMetaTags = () => {
    return (
      <MetaTags>
        <title>Asset Builder</title>
        <meta name='description' content='Search assets'/>
        <meta name='title' property='og:title' content='Explore Assets'/>
        <meta property='og:description' content='Search assets'/>
        <meta property='og:image' content={LogoImg}/>
        <meta property='og:type' content='website'/>
        <meta property='og:url' content={window.location.href}/>
        <meta property='twitter:title' content='Explore Assets'/>
        <meta property='twitter:description' content='Search assets'/>
        <meta property='twitter:image' content={LogoImg}/>
        <meta property='twitter:site' content='Explore Assets'/>
        <meta name='robots' content='nofollow' />
      </MetaTags>
    )
  }

  render() {
    return (
      <Container fluid className='sto-insights-page-container'>
        {this.renderMetaTags()}
        <Row noGutters className='search-assets-container'>
          <Col lg={1} />
          <Col>
            {this.renderSearchAssets()}
          </Col>
          <Col lg={1} />
        </Row>
      </Container>
    )
  }
}

export default SearchAssets
