import * as CONSTANTS from '../constants/admin'

const initialState = {
  tradedSymbols: {
    data: {},
    loaded: false,
    loading: false,
    error: ''
  },
  users: {
    data: {},
    loaded: false,
    loading: false,
    error: ''
  },
  reports: {
    data: {},
    loaded: false,
    loading: false,
    error: ''
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case CONSTANTS.ADMIN_GET_TRADED_SYMBOLS_REQUEST:
      return {
        ...state,
        tradedSymbols: {
          ...state.tradedSymbols,
          loading: true,
          loaded: false
        },
      }

    case CONSTANTS.ADMIN_GET_TRADED_SYMBOLS_SUCCESS:
      return {
        ...state,
        tradedSymbols: {
          ...state.tradedSymbols,
          loading: false,
          loaded: true,
          data: action.data
        },
      }

    case CONSTANTS.ADMIN_GET_TRADED_SYMBOLS_ERROR:
      return {
        ...state,
        tradedSymbols: {
          ...state.tradedSymbols,
          loading: false,
          loaded: false,
          error: ''
        },
      }

    /****************************************************/

    case CONSTANTS.ADMIN_SEARCH_USERS_REQUEST:
      return {
        ...state,
        users: {
          ...state.users,
          loading: true,
          loaded: false
        },
      }

    case CONSTANTS.ADMIN_SEARCH_USERS_SUCCESS:
      return {
        ...state,
        users: {
          ...state.users,
          loading: false,
          loaded: true,
          data: action.data
        },
      }

    case CONSTANTS.ADMIN_SEARCH_USERS_ERROR:
      return {
        ...state,
        users: {
          ...state.users,
          loading: false,
          loaded: false,
          error: ''
        },
      }

    case CONSTANTS.ADMIN_SEARCH_USERS_CLEAR:
      return {
        ...state,
        users: {
          data: {},
          loaded: false,
          loading: false,
          error: ''
        }
      }

    /****************************************************/

    case CONSTANTS.ADMIN_SEARCH_REPORTS_REQUEST:
      return {
        ...state,
        reports: {
          ...state.reports,
          loading: true,
          loaded: false
        },
      }

    case CONSTANTS.ADMIN_SEARCH_REPORTS_SUCCESS:
      return {
        ...state,
        reports: {
          ...state.reports,
          loading: false,
          loaded: true,
          data: action.data
        },
      }

    case CONSTANTS.ADMIN_SEARCH_REPORTS_ERROR:
      return {
        ...state,
        reports: {
          ...state.reports,
          loading: false,
          loaded: false,
          error: ''
        },
      }

    case CONSTANTS.ADMIN_SEARCH_REPORTS_CLEAR:
      return {
        ...state,
        reports: {
          data: {},
          loaded: false,
          loading: false,
          error: ''
        }
      }

    /****************************************************/

    default:
      return state;
  }
}
