import { put, takeLatest, call } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { RESET_PASSWORD_REQUEST } from '../../constants/resetpassword';
import {
  resetPasswordSuccess,
  resetPasswordError,
} from '../../actions/resetpassword';
import request from '../../../utils/apiRequest';

function* resetPassword(action) {
  try {
    const requestData = {
      email: action.email,
      code: action.code,
      password: action.password,
      cpassword: action.cpassword,
    };
    const responseData = yield call(
      request,
      'account/reset',
      'POST',
      requestData,
    );
    // toast.success('Success reset password');
    yield put(resetPasswordSuccess(responseData));
  } catch (err) {
    // toast.error('Error reset password');
    yield put(resetPasswordError(err));
  }
}

export default function* resetPasswordSaga() {
  yield takeLatest(RESET_PASSWORD_REQUEST, resetPassword);
}
