import * as CONSTANTS from '../constants/forgotpassword';
import { CLEAR_USER_CACHE } from '../constants/account'

const initialState = {
  forgotPassword: {
    success: false,
    error: '',
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    // case CLEAR_USER_CACHE:
    // state = initialState
    case CONSTANTS.FORGOT_PASSWORD_REQUEST:
      return {
        ...state,
        forgotPassword: {
          success: false,
        },
      };
    case 'RESET_FORGOT_PASSWORD_SUCCESS':
      return {
        ...state,
        forgotPassword: {
          success: false,
        },
      };
    case CONSTANTS.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        forgotPassword: {
          success: true,
        },
      };
    case CONSTANTS.FORGOT_PASSWORD_ERROR:
      return {
        ...state,
        forgotPassword: {
          error: action.err,
        },
      };
    default:
      return state;
  }
};
