import { connect } from 'react-redux';
import ForgotPassword from './ForgotPassword';
import './ForgotPassword.css';
import {
  forgotPasswordRequest,
  resetForgotPassword,
} from '../../redux/actions/forgotpassword';

const mapStateToProps = state => ({
  success: state.forgot.forgotPassword.success,
});

const mapDispatchToProps = {
  forgotPasswordRequest,
  resetForgotPassword,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ForgotPassword);
