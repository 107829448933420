import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import { Form, Row, Col, Jumbotron } from "react-bootstrap";
import { history } from '../../configureStore';
import { Link } from "react-router-dom";
import "./Register.css";
import { toast } from 'react-toastify'
import { initGA, logPageView, trackEvent } from "../../utils/analytics";
import ReCAPTCHA from "react-google-recaptcha";

class Register extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userName: "",
      password: "",
      email: "",
      firstName: "",
      lastName: "",
      countryOfOrigin: "",
      notARobot: false,
    };
  }

  componentDidMount() {
    initGA();
    logPageView();
  }


  componentWillUnmount() {
    //this.props.resetForgotPassword();
  }

  onChange = value => {
    // console.log(value)
    if (value) {
      this.setState({notARobot: true})
    } else {
      this.setState({notARobot: false})
    }
  }

  handleFormChange = fieldName => e => {
    this.setState({
      [fieldName]: e.target.value
    });
  };

  handleUsernameContainsDisallowedCharacters = username => {
    const disallowedCharacters = "!@#$%^&*(),.?:{}|<>"
    let validation = false
    for (let i = 0; i < username.length; i++) {
      if (disallowedCharacters.indexOf(username.charAt(i)) != -1) {
        validation = true
      }
    }
    return validation
  }

  handleSubmit = e => {
    e.preventDefault();

    const { email, password, userName, firstName, lastName } = this.state;
    const { signupRequest } = this.props;

    const usernameContainsSpecialCharacters = this.handleUsernameContainsDisallowedCharacters(userName)
    if (usernameContainsSpecialCharacters) {
      toast.info('Your username cannot contain special characters. Please remove them and try again.')
    } else {
      signupRequest(email, password, password, userName, firstName, lastName);
      trackEvent("User", "Registration", userName)
    }
  };

  handleCancel = e => {
    this.setState({
      userName: "",
      password: "",
      email: "",
      firstName: "",
      lastName: "",
      countryOfOrigin: ""
    });
  };

  render() {
    const registerButton = this.state.notARobot
      ? <Button onClick={this.handleSubmit} className='login-button' variant="primary">Register</Button>
      : <Button disabled className='login-button'>Register</Button>

    return (
      <Jumbotron className='login-background'>
          <Row className='login-header-row'>
            <h3>REGISTER</h3>
          </Row>
          <Row className='no-padding'>
            <Col md={3} />
              <Form as={Col} className='register-form-container'>
                <Form.Group as={Row} className='no-padding register-group' controlId="formBasicUsername">
                  <Form.Control className="register-control"
                    placeholder="User Name"
                    onChange={this.handleFormChange("userName")}
                    value={this.state.userName}
                  />
                  <Form.Text className="text-muted" />
                </Form.Group>
                <Form.Group as={Row} className='no-padding register-group' controlId="formBasicPassword">
                  <Form.Control className="register-control"
                    type="password"
                    placeholder="Password"
                    onChange={this.handleFormChange("password")}
                    value={this.state.password}
                  />
                </Form.Group>
                <Form.Group as={Row} className='no-padding register-group' controlId="formBasicEmail">
                  <Form.Control className="register-control"
                    type="email"
                    placeholder="Email"
                    onChange={this.handleFormChange("email")}
                    value={this.state.email}
                  />
                  <Form.Text className="text-muted" />
                </Form.Group>
                <Row className='no-padding recaptcha-row'>
                  <Col className='no-padding recaptcha-col' md={12}>
                    <ReCAPTCHA
                      className='recaptcha'
                      sitekey={this.props.siteKey}
                      onChange={this.onChange}
                    />
                    {registerButton}
                  </Col>
                </Row>
              </Form>
            <Col md={3} />
          </Row>
      </Jumbotron>
    );
  }
}

export default Register;
